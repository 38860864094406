// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as ProviderUser from "../../../models/ProviderUser.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providerUsers: field.required("providerUsers", ProviderUser.EditProvider.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, providerId) {
  var page = "page=" + String(pageNum);
  return Rest.$$fetch("/dashboard/providers/" + ID.toString(providerId) + "/provider_users?" + page, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
