// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../../../../libs/ID.res.js";
import * as Form from "../../../../../../../styleguide/forms/Form/Form.res.js";
import * as Hooks from "../../../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Continents from "../../../../../../../models/Continents.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Location from "../../../../../../../api/locations/Api_Location.res.js";
import * as Api_Provider from "../../../../../../../api/providers/Api_Provider.res.js";
import * as ErrorMessage from "../../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as ReactGeoSuggest from "../../../../../../../bindings/ReactGeoSuggest.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactGeoSuggestField from "../../../../../../../styleguide/forms/ReactGeoSuggestField/ReactGeoSuggestField.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Api_DataCenterFacilityLocation from "../../../../../../../api/data-center-facility-location/Api_DataCenterFacilityLocation.res.js";
import * as ProviderEditFormsScss from "../../../common/ProviderEditForms.scss";
import * as ProviderEditContainersScss from "../../../common/ProviderEditContainers.scss";

var containers = ProviderEditContainersScss;

var forms = ProviderEditFormsScss;

var validators_name = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.name.length;
      if (x !== 0) {
        if (x < 5) {
          return {
                  TAG: "Error",
                  _0: "Name must be longer than 5 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.name
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Name is required."
              };
      }
    })
};

var validators_fullAddress = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.fullAddress.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.fullAddress
              };
      } else {
        return {
                TAG: "Error",
                _0: "Address is required."
              };
      }
    })
};

var validators_streetAddress1 = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.streetAddress1.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.streetAddress1
              };
      } else {
        return {
                TAG: "Error",
                _0: "Street address is required."
              };
      }
    })
};

var validators_city = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.city.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.city
              };
      } else {
        return {
                TAG: "Error",
                _0: "City is required."
              };
      }
    })
};

var validators_locationState = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.locationState.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.locationState
              };
      } else {
        return {
                TAG: "Error",
                _0: "State is required."
              };
      }
    })
};

var validators_country = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.country.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.country
              };
      } else {
        return {
                TAG: "Error",
                _0: "Country is required."
              };
      }
    })
};

var validators_continent = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = Continents.fromString(input.continent);
      if (match === "Unknown") {
        return {
                TAG: "Error",
                _0: "Continent must exist."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.continent
              };
      }
    })
};

var validators_longitude = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.longitude;
      if (x === 0.0) {
        return {
                TAG: "Error",
                _0: "Latitude is required."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.longitude
              };
      }
    })
};

var validators_latitude = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.latitude;
      if (x === 0.0) {
        return {
                TAG: "Error",
                _0: "Latitude is required."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.latitude
              };
      }
    })
};

var validators = {
  name: validators_name,
  fullAddress: validators_fullAddress,
  streetAddress1: validators_streetAddress1,
  city: validators_city,
  locationState: validators_locationState,
  zip: undefined,
  country: validators_country,
  continent: validators_continent,
  longitude: validators_longitude,
  latitude: validators_latitude,
  nearestAirportOverride: undefined,
  totalPowerMw: undefined,
  powerDensity: undefined,
  grossBuildingSize: undefined,
  grossColocationSpace: undefined,
  metaTitle: undefined,
  metaDescription: undefined
};

function initialFieldsStatuses(_input) {
  return {
          name: "Pristine",
          fullAddress: "Pristine",
          streetAddress1: "Pristine",
          city: "Pristine",
          locationState: "Pristine",
          zip: "Pristine",
          country: "Pristine",
          continent: "Pristine",
          longitude: "Pristine",
          latitude: "Pristine",
          nearestAirportOverride: "Pristine",
          totalPowerMw: "Pristine",
          powerDensity: "Pristine",
          grossBuildingSize: "Pristine",
          grossColocationSpace: "Pristine",
          metaTitle: "Pristine",
          metaDescription: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.name;
  var tmp;
  tmp = typeof match !== "object" ? validators.name.validate(input) : match._0;
  var match$1 = fieldsStatuses.fullAddress;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.fullAddress.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.streetAddress1;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.streetAddress1.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.city;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.city.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.locationState;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.locationState.validate(input) : match$4._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.zip
  };
  var match$5 = fieldsStatuses.country;
  var tmp$5;
  tmp$5 = typeof match$5 !== "object" ? validators.country.validate(input) : match$5._0;
  var match$6 = fieldsStatuses.continent;
  var tmp$6;
  tmp$6 = typeof match$6 !== "object" ? validators.continent.validate(input) : match$6._0;
  var match$7 = fieldsStatuses.longitude;
  var tmp$7;
  tmp$7 = typeof match$7 !== "object" ? validators.longitude.validate(input) : match$7._0;
  var match$8 = fieldsStatuses.latitude;
  var tmp$8;
  tmp$8 = typeof match$8 !== "object" ? validators.latitude.validate(input) : match$8._0;
  var match_0$1 = {
    TAG: "Ok",
    _0: input.nearestAirportOverride
  };
  var match_0$2 = {
    TAG: "Ok",
    _0: input.totalPowerMw
  };
  var match_0$3 = {
    TAG: "Ok",
    _0: input.powerDensity
  };
  var match_0$4 = {
    TAG: "Ok",
    _0: input.grossBuildingSize
  };
  var match_0$5 = {
    TAG: "Ok",
    _0: input.grossColocationSpace
  };
  var match_0$6 = {
    TAG: "Ok",
    _0: input.metaTitle
  };
  var match_0$7 = {
    TAG: "Ok",
    _0: input.metaDescription
  };
  var nameResult = tmp;
  var nameResult$1;
  if (nameResult.TAG === "Ok") {
    var fullAddressResult = tmp$1;
    if (fullAddressResult.TAG === "Ok") {
      var streetAddress1Result = tmp$2;
      if (streetAddress1Result.TAG === "Ok") {
        var cityResult = tmp$3;
        if (cityResult.TAG === "Ok") {
          var locationStateResult = tmp$4;
          if (locationStateResult.TAG === "Ok") {
            var zipResult = match_0;
            if (zipResult.TAG === "Ok") {
              var countryResult = tmp$5;
              if (countryResult.TAG === "Ok") {
                var continentResult = tmp$6;
                if (continentResult.TAG === "Ok") {
                  var longitudeResult = tmp$7;
                  if (longitudeResult.TAG === "Ok") {
                    var latitudeResult = tmp$8;
                    if (latitudeResult.TAG === "Ok") {
                      var nearestAirportOverrideResult = match_0$1;
                      if (nearestAirportOverrideResult.TAG === "Ok") {
                        var totalPowerMwResult = match_0$2;
                        if (totalPowerMwResult.TAG === "Ok") {
                          var powerDensityResult = match_0$3;
                          if (powerDensityResult.TAG === "Ok") {
                            var grossBuildingSizeResult = match_0$4;
                            if (grossBuildingSizeResult.TAG === "Ok") {
                              var grossColocationSpaceResult = match_0$5;
                              if (grossColocationSpaceResult.TAG === "Ok") {
                                var metaTitleResult = match_0$6;
                                if (metaTitleResult.TAG === "Ok") {
                                  var metaDescriptionResult = match_0$7;
                                  if (metaDescriptionResult.TAG === "Ok") {
                                    return {
                                            TAG: "Valid",
                                            output: {
                                              name: nameResult._0,
                                              fullAddress: fullAddressResult._0,
                                              streetAddress1: streetAddress1Result._0,
                                              city: cityResult._0,
                                              locationState: locationStateResult._0,
                                              zip: zipResult._0,
                                              country: countryResult._0,
                                              continent: continentResult._0,
                                              longitude: longitudeResult._0,
                                              latitude: latitudeResult._0,
                                              nearestAirportOverride: nearestAirportOverrideResult._0,
                                              totalPowerMw: totalPowerMwResult._0,
                                              powerDensity: powerDensityResult._0,
                                              grossBuildingSize: grossBuildingSizeResult._0,
                                              grossColocationSpace: grossColocationSpaceResult._0,
                                              metaTitle: metaTitleResult._0,
                                              metaDescription: metaDescriptionResult._0
                                            },
                                            fieldsStatuses: {
                                              name: {
                                                TAG: "Dirty",
                                                _0: nameResult,
                                                _1: "Shown"
                                              },
                                              fullAddress: {
                                                TAG: "Dirty",
                                                _0: fullAddressResult,
                                                _1: "Shown"
                                              },
                                              streetAddress1: {
                                                TAG: "Dirty",
                                                _0: streetAddress1Result,
                                                _1: "Shown"
                                              },
                                              city: {
                                                TAG: "Dirty",
                                                _0: cityResult,
                                                _1: "Shown"
                                              },
                                              locationState: {
                                                TAG: "Dirty",
                                                _0: locationStateResult,
                                                _1: "Shown"
                                              },
                                              zip: {
                                                TAG: "Dirty",
                                                _0: zipResult,
                                                _1: "Hidden"
                                              },
                                              country: {
                                                TAG: "Dirty",
                                                _0: countryResult,
                                                _1: "Shown"
                                              },
                                              continent: {
                                                TAG: "Dirty",
                                                _0: continentResult,
                                                _1: "Shown"
                                              },
                                              longitude: {
                                                TAG: "Dirty",
                                                _0: longitudeResult,
                                                _1: "Shown"
                                              },
                                              latitude: {
                                                TAG: "Dirty",
                                                _0: latitudeResult,
                                                _1: "Shown"
                                              },
                                              nearestAirportOverride: {
                                                TAG: "Dirty",
                                                _0: nearestAirportOverrideResult,
                                                _1: "Hidden"
                                              },
                                              totalPowerMw: {
                                                TAG: "Dirty",
                                                _0: totalPowerMwResult,
                                                _1: "Hidden"
                                              },
                                              powerDensity: {
                                                TAG: "Dirty",
                                                _0: powerDensityResult,
                                                _1: "Hidden"
                                              },
                                              grossBuildingSize: {
                                                TAG: "Dirty",
                                                _0: grossBuildingSizeResult,
                                                _1: "Hidden"
                                              },
                                              grossColocationSpace: {
                                                TAG: "Dirty",
                                                _0: grossColocationSpaceResult,
                                                _1: "Hidden"
                                              },
                                              metaTitle: {
                                                TAG: "Dirty",
                                                _0: metaTitleResult,
                                                _1: "Hidden"
                                              },
                                              metaDescription: {
                                                TAG: "Dirty",
                                                _0: metaDescriptionResult,
                                                _1: "Hidden"
                                              }
                                            },
                                            collectionsStatuses: undefined
                                          };
                                  }
                                  nameResult$1 = nameResult;
                                } else {
                                  nameResult$1 = nameResult;
                                }
                              } else {
                                nameResult$1 = nameResult;
                              }
                            } else {
                              nameResult$1 = nameResult;
                            }
                          } else {
                            nameResult$1 = nameResult;
                          }
                        } else {
                          nameResult$1 = nameResult;
                        }
                      } else {
                        nameResult$1 = nameResult;
                      }
                    } else {
                      nameResult$1 = nameResult;
                    }
                  } else {
                    nameResult$1 = nameResult;
                  }
                } else {
                  nameResult$1 = nameResult;
                }
              } else {
                nameResult$1 = nameResult;
              }
            } else {
              nameResult$1 = nameResult;
            }
          } else {
            nameResult$1 = nameResult;
          }
        } else {
          nameResult$1 = nameResult;
        }
      } else {
        nameResult$1 = nameResult;
      }
    } else {
      nameResult$1 = nameResult;
    }
  } else {
    nameResult$1 = nameResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            name: {
              TAG: "Dirty",
              _0: nameResult$1,
              _1: "Shown"
            },
            fullAddress: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            streetAddress1: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            city: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            locationState: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            },
            zip: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            country: {
              TAG: "Dirty",
              _0: tmp$5,
              _1: "Shown"
            },
            continent: {
              TAG: "Dirty",
              _0: tmp$6,
              _1: "Shown"
            },
            longitude: {
              TAG: "Dirty",
              _0: tmp$7,
              _1: "Shown"
            },
            latitude: {
              TAG: "Dirty",
              _0: tmp$8,
              _1: "Shown"
            },
            nearestAirportOverride: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            totalPowerMw: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            powerDensity: {
              TAG: "Dirty",
              _0: match_0$3,
              _1: "Hidden"
            },
            grossBuildingSize: {
              TAG: "Dirty",
              _0: match_0$4,
              _1: "Hidden"
            },
            grossColocationSpace: {
              TAG: "Dirty",
              _0: match_0$5,
              _1: "Hidden"
            },
            metaTitle: {
              TAG: "Dirty",
              _0: match_0$6,
              _1: "Hidden"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: match_0$7,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurNameField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: status,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurFullAddressField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.fullAddress, validators_fullAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: status,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurStreetAddress1Field" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.streetAddress1, validators_streetAddress1, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: status,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurCityField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.city, validators_city, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: status,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLocationStateField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.locationState, validators_locationState, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: status,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurZipField" :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.zip, state.fieldsStatuses.zip, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: status,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurCountryField" :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.country, validators_country, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: status,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurContinentField" :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.continent, validators_continent, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: status,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLongitudeField" :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.longitude, validators_longitude, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: status,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLatitudeField" :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.latitude, validators_latitude, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: status,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNearestAirportOverrideField" :
                  var result$10 = Formality.validateFieldOnBlurWithoutValidator(state.input.nearestAirportOverride, state.fieldsStatuses.nearestAirportOverride, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: status,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurTotalPowerMwField" :
                  var result$11 = Formality.validateFieldOnBlurWithoutValidator(state.input.totalPowerMw, state.fieldsStatuses.totalPowerMw, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: status,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurPowerDensityField" :
                  var result$12 = Formality.validateFieldOnBlurWithoutValidator(state.input.powerDensity, state.fieldsStatuses.powerDensity, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: status,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurGrossBuildingSizeField" :
                  var result$13 = Formality.validateFieldOnBlurWithoutValidator(state.input.grossBuildingSize, state.fieldsStatuses.grossBuildingSize, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: status,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurGrossColocationSpaceField" :
                  var result$14 = Formality.validateFieldOnBlurWithoutValidator(state.input.grossColocationSpace, state.fieldsStatuses.grossColocationSpace, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: status,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaTitleField" :
                  var result$15 = Formality.validateFieldOnBlurWithoutValidator(state.input.metaTitle, state.fieldsStatuses.metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: status,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$16 = Formality.validateFieldOnBlurWithoutValidator(state.input.metaDescription, state.fieldsStatuses.metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  fullAddress: init.fullAddress,
                                  streetAddress1: init.streetAddress1,
                                  city: init.city,
                                  locationState: init.locationState,
                                  zip: init.zip,
                                  country: init.country,
                                  continent: init.continent,
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  nearestAirportOverride: init.nearestAirportOverride,
                                  totalPowerMw: init.totalPowerMw,
                                  powerDensity: init.powerDensity,
                                  grossBuildingSize: init.grossBuildingSize,
                                  grossColocationSpace: init.grossColocationSpace,
                                  metaTitle: init.metaTitle,
                                  metaDescription: status
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateNameField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: status,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateFullAddressField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.fullAddress, state.submissionStatus, validators_fullAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: status,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateStreetAddress1Field" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.streetAddress1, state.submissionStatus, validators_streetAddress1, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: status,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateCityField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.city, state.submissionStatus, validators_city, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: status,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLocationStateField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.locationState, state.submissionStatus, validators_locationState, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: status,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateZipField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.zip, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: status,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateCountryField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.country, state.submissionStatus, validators_country, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: status,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateContinentField" :
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.continent, state.submissionStatus, validators_continent, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: status,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLongitudeField" :
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.longitude, state.submissionStatus, validators_longitude, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: status,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLatitudeField" :
                  var nextInput$9 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.latitude, state.submissionStatus, validators_latitude, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: status,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNearestAirportOverrideField" :
                  var nextInput$10 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$10.nearestAirportOverride, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: status,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateTotalPowerMwField" :
                  var nextInput$11 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$11.totalPowerMw, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: status,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdatePowerDensityField" :
                  var nextInput$12 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$12.powerDensity, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: status,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateGrossBuildingSizeField" :
                  var nextInput$13 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$13.grossBuildingSize, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: status,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateGrossColocationSpaceField" :
                  var nextInput$14 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$14.grossColocationSpace, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: status,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaTitleField" :
                  var nextInput$15 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$15.metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: status,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$16 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$16.metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            fullAddress: init.fullAddress,
                                            streetAddress1: init.streetAddress1,
                                            city: init.city,
                                            locationState: init.locationState,
                                            zip: init.zip,
                                            country: init.country,
                                            continent: init.continent,
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            nearestAirportOverride: init.nearestAirportOverride,
                                            totalPowerMw: init.totalPowerMw,
                                            powerDensity: init.powerDensity,
                                            grossBuildingSize: init.grossBuildingSize,
                                            grossColocationSpace: init.grossColocationSpace,
                                            metaTitle: init.metaTitle,
                                            metaDescription: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateFullAddress: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateFullAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateStreetAddress1: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateStreetAddress1Field",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateCity: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateCityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLocationState: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLocationStateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateZip: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateZipField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateCountry: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateCountryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateContinent: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateContinentField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLongitude: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLongitudeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLatitude: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLatitudeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateNearestAirportOverride: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNearestAirportOverrideField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateTotalPowerMw: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTotalPowerMwField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updatePowerDensity: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePowerDensityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateGrossBuildingSize: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateGrossBuildingSizeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateGrossColocationSpace: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateGrossColocationSpaceField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurName: (function () {
              dispatch("BlurNameField");
            }),
          blurFullAddress: (function () {
              dispatch("BlurFullAddressField");
            }),
          blurStreetAddress1: (function () {
              dispatch("BlurStreetAddress1Field");
            }),
          blurCity: (function () {
              dispatch("BlurCityField");
            }),
          blurLocationState: (function () {
              dispatch("BlurLocationStateField");
            }),
          blurZip: (function () {
              dispatch("BlurZipField");
            }),
          blurCountry: (function () {
              dispatch("BlurCountryField");
            }),
          blurContinent: (function () {
              dispatch("BlurContinentField");
            }),
          blurLongitude: (function () {
              dispatch("BlurLongitudeField");
            }),
          blurLatitude: (function () {
              dispatch("BlurLatitudeField");
            }),
          blurNearestAirportOverride: (function () {
              dispatch("BlurNearestAirportOverrideField");
            }),
          blurTotalPowerMw: (function () {
              dispatch("BlurTotalPowerMwField");
            }),
          blurPowerDensity: (function () {
              dispatch("BlurPowerDensityField");
            }),
          blurGrossBuildingSize: (function () {
              dispatch("BlurGrossBuildingSizeField");
            }),
          blurGrossColocationSpace: (function () {
              dispatch("BlurGrossColocationSpaceField");
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          fullAddressResult: Formality.exposeFieldResult(state.fieldsStatuses.fullAddress),
          streetAddress1Result: Formality.exposeFieldResult(state.fieldsStatuses.streetAddress1),
          cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
          locationStateResult: Formality.exposeFieldResult(state.fieldsStatuses.locationState),
          zipResult: Formality.exposeFieldResult(state.fieldsStatuses.zip),
          countryResult: Formality.exposeFieldResult(state.fieldsStatuses.country),
          continentResult: Formality.exposeFieldResult(state.fieldsStatuses.continent),
          longitudeResult: Formality.exposeFieldResult(state.fieldsStatuses.longitude),
          latitudeResult: Formality.exposeFieldResult(state.fieldsStatuses.latitude),
          nearestAirportOverrideResult: Formality.exposeFieldResult(state.fieldsStatuses.nearestAirportOverride),
          totalPowerMwResult: Formality.exposeFieldResult(state.fieldsStatuses.totalPowerMw),
          powerDensityResult: Formality.exposeFieldResult(state.fieldsStatuses.powerDensity),
          grossBuildingSizeResult: Formality.exposeFieldResult(state.fieldsStatuses.grossBuildingSize),
          grossColocationSpaceResult: Formality.exposeFieldResult(state.fieldsStatuses.grossColocationSpace),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var exit = 0;
              var tmp = match.name;
              if (typeof tmp !== "object") {
                var tmp$1 = match.fullAddress;
                if (typeof tmp$1 !== "object") {
                  var tmp$2 = match.streetAddress1;
                  if (typeof tmp$2 !== "object") {
                    var tmp$3 = match.city;
                    if (typeof tmp$3 !== "object") {
                      var tmp$4 = match.locationState;
                      if (typeof tmp$4 !== "object") {
                        var tmp$5 = match.zip;
                        if (typeof tmp$5 !== "object") {
                          var tmp$6 = match.country;
                          if (typeof tmp$6 !== "object") {
                            var tmp$7 = match.continent;
                            if (typeof tmp$7 !== "object") {
                              var tmp$8 = match.longitude;
                              if (typeof tmp$8 !== "object") {
                                var tmp$9 = match.latitude;
                                if (typeof tmp$9 !== "object") {
                                  var tmp$10 = match.nearestAirportOverride;
                                  if (typeof tmp$10 !== "object") {
                                    var tmp$11 = match.totalPowerMw;
                                    if (typeof tmp$11 !== "object") {
                                      var tmp$12 = match.powerDensity;
                                      if (typeof tmp$12 !== "object") {
                                        var tmp$13 = match.grossBuildingSize;
                                        if (typeof tmp$13 !== "object") {
                                          var tmp$14 = match.grossColocationSpace;
                                          if (typeof tmp$14 !== "object") {
                                            var tmp$15 = match.metaTitle;
                                            if (typeof tmp$15 !== "object") {
                                              var tmp$16 = match.metaDescription;
                                              if (typeof tmp$16 !== "object") {
                                                return false;
                                              }
                                              exit = 1;
                                            } else {
                                              exit = 1;
                                            }
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                      } else {
                                        exit = 1;
                                      }
                                    } else {
                                      exit = 1;
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                          } else {
                            exit = 1;
                          }
                        } else {
                          exit = 1;
                        }
                      } else {
                        exit = 1;
                      }
                    } else {
                      exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            return true;
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var LocationForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditLocationGeneralInfo(props) {
  var updateLocation = props.updateLocation;
  var userRole = props.userRole;
  var live = props.live;
  var $$location = props.location;
  var liveProvider = props.liveProvider;
  var initialInput = React.useMemo((function () {
          return {
                  name: $$location.currentDraft.name,
                  fullAddress: $$location.currentDraft.fullAddress,
                  streetAddress1: $$location.currentDraft.streetAddress1,
                  city: $$location.currentDraft.city,
                  locationState: $$location.currentDraft.state,
                  zip: $$location.currentDraft.zip,
                  country: $$location.currentDraft.country,
                  continent: $$location.currentDraft.continent,
                  longitude: $$location.currentDraft.longitude,
                  latitude: $$location.currentDraft.latitude,
                  nearestAirportOverride: $$location.currentDraft.nearestAirportOverride,
                  totalPowerMw: $$location.currentDraft.totalPowerMw,
                  powerDensity: $$location.currentDraft.powerDensity,
                  grossBuildingSize: $$location.currentDraft.grossBuildingSize,
                  grossColocationSpace: $$location.currentDraft.grossColocationSpace,
                  metaTitle: $$location.currentDraft.metaTitle,
                  metaDescription: $$location.currentDraft.metaDescription
                };
        }), []);
  var match = Hooks.useReducer({
        nameValid: true,
        timeout: undefined
      }, (function (state, action) {
          switch (action.TAG) {
            case "SetNameValid" :
                return {
                        TAG: "Update",
                        _0: {
                          nameValid: action._0,
                          timeout: state.timeout
                        }
                      };
            case "ValidateName" :
                var id = action._1;
                var name = action._0;
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api_Provider.validateLocationName(Caml_option.some($$location.id), id, name), (function (x) {
                                    if (x.TAG === "Ok") {
                                      return dispatch({
                                                  TAG: "SetNameValid",
                                                  _0: x._0.valid
                                                });
                                    }
                                    SentryLogger.error1({
                                          rootModule: "ProviderEditLocationGeneralInfo",
                                          subModulePath: /* [] */0,
                                          value: "make",
                                          fullPath: "ProviderEditLocationGeneralInfo.make"
                                        }, "CreateNewLocation::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch({
                                          TAG: "SetNameValid",
                                          _0: false
                                        });
                                  }));
                          })
                      };
            case "SetTimeout" :
                return {
                        TAG: "Update",
                        _0: {
                          nameValid: state.nameValid,
                          timeout: action._0
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_Location.updateDraftGeneralInfo($$location.currentDraft.id, {
                    name: output.name.trim(),
                    fullAddress: output.fullAddress,
                    streetAddress1: output.streetAddress1,
                    city: output.city,
                    state: output.locationState,
                    zip: output.zip,
                    country: output.country,
                    continent: output.continent,
                    latitude: output.latitude,
                    longitude: output.longitude,
                    totalPowerMw: output.totalPowerMw,
                    powerDensity: output.powerDensity,
                    grossBuildingSize: output.grossBuildingSize,
                    grossColocationSpace: output.grossColocationSpace,
                    metaTitle: output.metaTitle,
                    metaDescription: output.metaDescription,
                    nearestAirportOverride: output.nearestAirportOverride
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditLocationGeneralInfo",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditLocationGeneralInfo.make"
                              }, "UpdateLocationDraft::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  var res = x._0;
                  updateLocation(res);
                  cb.notifyOnSuccess({
                        name: res.currentDraft.name,
                        fullAddress: res.currentDraft.fullAddress,
                        streetAddress1: res.currentDraft.streetAddress1,
                        city: res.currentDraft.city,
                        locationState: res.currentDraft.state,
                        zip: res.currentDraft.zip,
                        country: res.currentDraft.country,
                        continent: res.currentDraft.continent,
                        longitude: res.currentDraft.longitude,
                        latitude: res.currentDraft.latitude,
                        nearestAirportOverride: res.currentDraft.nearestAirportOverride,
                        totalPowerMw: res.currentDraft.totalPowerMw,
                        powerDensity: res.currentDraft.powerDensity,
                        grossBuildingSize: res.currentDraft.grossBuildingSize,
                        grossColocationSpace: res.currentDraft.grossColocationSpace,
                        metaTitle: res.currentDraft.metaTitle,
                        metaDescription: res.currentDraft.metaDescription
                      });
                }));
        }));
  var submitForm = function (param) {
    var match = state.timeout;
    if (match !== undefined) {
      clearTimeout(Belt_Option.getExn(state.timeout));
      return dispatch({
                  TAG: "SetTimeout",
                  _0: Caml_option.some(setTimeout((function () {
                              form.submit();
                            }), 1200))
                });
    } else {
      return dispatch({
                  TAG: "SetTimeout",
                  _0: Caml_option.some(setTimeout((function () {
                              form.submit();
                            }), 1200))
                });
    }
  };
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: forms.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var totalOptions = $$location.facilities.length;
  var numOfRows = totalOptions / 3 | 0;
  var match$1 = form.input.name.length;
  var match$2 = state.nameValid;
  var match$3 = Continents.fromString(form.input.continent);
  var tmp;
  tmp = match$3 === "Unknown" ? false : true;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(H3.make, {
                      children: "General Information"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs(Form.make, {
                            className: forms.editGeneralInfoForm,
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "location-name",
                                            children: "Location Name"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "location-name",
                                            value: live === "Live" ? $$location.name : form.input.name,
                                            placeholder: "Name of location",
                                            disabled: live === "Live" || form.submitting ? true : false,
                                            className: $$location.name === form.input.name ? "" : forms.fieldChanged,
                                            onChange: (function ($$event) {
                                                var value = $$event.target.value;
                                                dispatch({
                                                      TAG: "ValidateName",
                                                      _0: value.trim(),
                                                      _1: liveProvider.id
                                                    });
                                                if (state.nameValid) {
                                                  form.updateName((function (input, value) {
                                                          return {
                                                                  name: value,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: input.streetAddress1,
                                                                  city: input.city,
                                                                  locationState: input.locationState,
                                                                  zip: input.zip,
                                                                  country: input.country,
                                                                  continent: input.continent,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), value);
                                                  return submitForm();
                                                }
                                                
                                              })
                                          }),
                                      fieldError(form.nameResult),
                                      match$1 !== 0 && !match$2 ? JsxRuntime.jsx(ErrorMessage.make, {
                                              className: forms.error,
                                              children: "Name is already taken"
                                            }) : null,
                                      JsxRuntime.jsx("div", {
                                            children: "Location URL: https://www.datacenters.com" + $$location.slug,
                                            className: forms.subText
                                          })
                                    ],
                                    className: forms.fieldRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "locations--address",
                                            children: "Address"
                                          }),
                                      JsxRuntime.jsx(ReactGeoSuggestField.make, {
                                            id: "location-address",
                                            value: live === "Live" ? $$location.fullAddress : form.input.fullAddress,
                                            radius: "20",
                                            placeholder: "Type and select an address.",
                                            disabled: live === "Live" || form.submitting ? true : false,
                                            className: $$location.fullAddress === form.input.fullAddress ? "" : forms.fieldChanged,
                                            location: new (google.maps.LatLng)(form.input.latitude, form.input.longitude),
                                            onSuggestSelect: (function (suggest) {
                                                var result = ReactGeoSuggest.ReactGeoSuggestData.fromJson(suggest);
                                                form.updateFullAddress((function (input, value) {
                                                        return {
                                                                name: input.name,
                                                                fullAddress: value,
                                                                streetAddress1: input.streetAddress1,
                                                                city: input.city,
                                                                locationState: input.locationState,
                                                                zip: input.zip,
                                                                country: input.country,
                                                                continent: input.continent,
                                                                longitude: input.longitude,
                                                                latitude: input.latitude,
                                                                nearestAirportOverride: input.nearestAirportOverride,
                                                                totalPowerMw: input.totalPowerMw,
                                                                powerDensity: input.powerDensity,
                                                                grossBuildingSize: input.grossBuildingSize,
                                                                grossColocationSpace: input.grossColocationSpace,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription
                                                              };
                                                      }), Belt_Option.getExn(result.description));
                                                form.updateLatitude((function (input, value) {
                                                        return {
                                                                name: input.name,
                                                                fullAddress: input.fullAddress,
                                                                streetAddress1: input.streetAddress1,
                                                                city: input.city,
                                                                locationState: input.locationState,
                                                                zip: input.zip,
                                                                country: input.country,
                                                                continent: input.continent,
                                                                longitude: input.longitude,
                                                                latitude: value,
                                                                nearestAirportOverride: input.nearestAirportOverride,
                                                                totalPowerMw: input.totalPowerMw,
                                                                powerDensity: input.powerDensity,
                                                                grossBuildingSize: input.grossBuildingSize,
                                                                grossColocationSpace: input.grossColocationSpace,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription
                                                              };
                                                      }), result.location.lat);
                                                form.updateLongitude((function (input, value) {
                                                        return {
                                                                name: input.name,
                                                                fullAddress: input.fullAddress,
                                                                streetAddress1: input.streetAddress1,
                                                                city: input.city,
                                                                locationState: input.locationState,
                                                                zip: input.zip,
                                                                country: input.country,
                                                                continent: input.continent,
                                                                longitude: value,
                                                                latitude: input.latitude,
                                                                nearestAirportOverride: input.nearestAirportOverride,
                                                                totalPowerMw: input.totalPowerMw,
                                                                powerDensity: input.powerDensity,
                                                                grossBuildingSize: input.grossBuildingSize,
                                                                grossColocationSpace: input.grossColocationSpace,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription
                                                              };
                                                      }), result.location.lng);
                                                var gmaps = result.gmaps;
                                                if (gmaps !== undefined) {
                                                  console.log(gmaps);
                                                  var addresses = gmaps.address_components;
                                                  var streetNumber = {
                                                    contents: ""
                                                  };
                                                  var route = {
                                                    contents: ""
                                                  };
                                                  var cityOrTown = {
                                                    contents: ""
                                                  };
                                                  var stateOrProvince = {
                                                    contents: ""
                                                  };
                                                  var country = {
                                                    contents: ""
                                                  };
                                                  var zip = {
                                                    contents: ""
                                                  };
                                                  var countryCode = {
                                                    contents: ""
                                                  };
                                                  Belt_Array.forEach(addresses, (function (address) {
                                                          var addressType = Belt_Array.get(address.types, 0);
                                                          if (addressType !== undefined) {
                                                            if (addressType === "street_number") {
                                                              streetNumber.contents = address.long_name;
                                                            }
                                                            if (addressType === "route") {
                                                              route.contents = address.long_name;
                                                            }
                                                            if (addressType === "locality" || addressType === "postal_town") {
                                                              cityOrTown.contents = address.long_name;
                                                            }
                                                            if (addressType === "administrative_area_level_1") {
                                                              stateOrProvince.contents = address.long_name;
                                                              if (cityOrTown.contents === "") {
                                                                cityOrTown.contents = address.long_name;
                                                              }
                                                              
                                                            }
                                                            if (addressType === "country") {
                                                              country.contents = address.long_name;
                                                              countryCode.contents = address.short_name;
                                                              if (stateOrProvince.contents === "") {
                                                                stateOrProvince.contents = address.long_name;
                                                              }
                                                              
                                                            }
                                                            if (addressType === "postal_code") {
                                                              zip.contents = address.long_name;
                                                              return ;
                                                            } else {
                                                              return ;
                                                            }
                                                          }
                                                          
                                                        }));
                                                  form.updateStreetAddress1((function (input, value) {
                                                          return {
                                                                  name: input.name,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: value,
                                                                  city: input.city,
                                                                  locationState: input.locationState,
                                                                  zip: input.zip,
                                                                  country: input.country,
                                                                  continent: input.continent,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), streetNumber.contents + " " + route.contents);
                                                  form.updateCity((function (input, value) {
                                                          return {
                                                                  name: input.name,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: input.streetAddress1,
                                                                  city: value,
                                                                  locationState: input.locationState,
                                                                  zip: input.zip,
                                                                  country: input.country,
                                                                  continent: input.continent,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), cityOrTown.contents);
                                                  form.updateLocationState((function (input, value) {
                                                          return {
                                                                  name: input.name,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: input.streetAddress1,
                                                                  city: input.city,
                                                                  locationState: value,
                                                                  zip: input.zip,
                                                                  country: input.country,
                                                                  continent: input.continent,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), stateOrProvince.contents);
                                                  form.updateCountry((function (input, value) {
                                                          return {
                                                                  name: input.name,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: input.streetAddress1,
                                                                  city: input.city,
                                                                  locationState: input.locationState,
                                                                  zip: input.zip,
                                                                  country: value,
                                                                  continent: input.continent,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), country.contents);
                                                  form.updateZip((function (input, value) {
                                                          return {
                                                                  name: input.name,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: input.streetAddress1,
                                                                  city: input.city,
                                                                  locationState: input.locationState,
                                                                  zip: value,
                                                                  country: input.country,
                                                                  continent: input.continent,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), zip.contents);
                                                  form.updateContinent((function (input, value) {
                                                          return {
                                                                  name: input.name,
                                                                  fullAddress: input.fullAddress,
                                                                  streetAddress1: input.streetAddress1,
                                                                  city: input.city,
                                                                  locationState: input.locationState,
                                                                  zip: input.zip,
                                                                  country: input.country,
                                                                  continent: value,
                                                                  longitude: input.longitude,
                                                                  latitude: input.latitude,
                                                                  nearestAirportOverride: input.nearestAirportOverride,
                                                                  totalPowerMw: input.totalPowerMw,
                                                                  powerDensity: input.powerDensity,
                                                                  grossBuildingSize: input.grossBuildingSize,
                                                                  grossColocationSpace: input.grossColocationSpace,
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription
                                                                };
                                                        }), Continents.toString(Continents.fromCountryCode(countryCode.contents)));
                                                }
                                                submitForm();
                                              })
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Type in a full address for the data center, which will auto-populate the fields below.\n               Must be a valid, complete address to work.",
                                            className: forms.subText
                                          }),
                                      fieldError(form.fullAddressResult)
                                    ],
                                    className: forms.fieldRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-city",
                                                    children: "City"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-city",
                                                    value: live === "Live" ? $$location.city : form.input.city,
                                                    disabled: true,
                                                    className: $$location.city === form.input.city ? "" : forms.fieldChanged
                                                  }),
                                              fieldError(form.cityResult)
                                            ],
                                            className: forms.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-locationState",
                                                    children: "State"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-locationState",
                                                    value: live === "Live" ? $$location.state : form.input.locationState,
                                                    disabled: true,
                                                    className: $$location.state === form.input.locationState ? "" : forms.fieldChanged
                                                  }),
                                              fieldError(form.locationStateResult)
                                            ],
                                            className: forms.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-Zip",
                                                    children: "Zip"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-zip",
                                                    value: live === "Live" ? $$location.zip : form.input.zip,
                                                    disabled: true,
                                                    className: $$location.zip === form.input.zip ? "" : forms.fieldChanged
                                                  }),
                                              fieldError(form.zipResult)
                                            ],
                                            className: forms.fieldColumn
                                          })
                                    ],
                                    className: forms.threeInRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-country",
                                                    children: "Country"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-country",
                                                    value: live === "Live" ? $$location.country : form.input.country,
                                                    disabled: true,
                                                    className: $$location.country === form.input.country ? "" : forms.fieldChanged
                                                  }),
                                              fieldError(form.countryResult)
                                            ],
                                            className: forms.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-continent",
                                                    children: "Continent"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-continent",
                                                    value: live === "Live" ? $$location.continent : form.input.continent,
                                                    disabled: tmp,
                                                    className: $$location.continent === form.input.continent ? "" : forms.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        form.updateContinent((function (input, value) {
                                                                return {
                                                                        name: input.name,
                                                                        fullAddress: input.fullAddress,
                                                                        streetAddress1: input.streetAddress1,
                                                                        city: input.city,
                                                                        locationState: input.locationState,
                                                                        zip: input.zip,
                                                                        country: input.country,
                                                                        continent: value,
                                                                        longitude: input.longitude,
                                                                        latitude: input.latitude,
                                                                        nearestAirportOverride: input.nearestAirportOverride,
                                                                        totalPowerMw: input.totalPowerMw,
                                                                        powerDensity: input.powerDensity,
                                                                        grossBuildingSize: input.grossBuildingSize,
                                                                        grossColocationSpace: input.grossColocationSpace,
                                                                        metaTitle: input.metaTitle,
                                                                        metaDescription: input.metaDescription
                                                                      };
                                                              }), $$event.target.value);
                                                      })
                                                  }),
                                              fieldError(form.continentResult)
                                            ],
                                            className: forms.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-nearestAirport",
                                                    children: "Nearest Airport (miles)"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-nearestAirport",
                                                    value: live === "Live" ? $$location.nearestAirportOverride : form.input.nearestAirportOverride,
                                                    placeholder: $$location.currentDraft.nearestAirport,
                                                    disabled: live === "Live" || form.submitting ? true : userRole !== "Admin",
                                                    className: $$location.nearestAirportOverride === form.input.nearestAirportOverride ? "" : forms.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        form.updateNearestAirportOverride((function (input, value) {
                                                                return {
                                                                        name: input.name,
                                                                        fullAddress: input.fullAddress,
                                                                        streetAddress1: input.streetAddress1,
                                                                        city: input.city,
                                                                        locationState: input.locationState,
                                                                        zip: input.zip,
                                                                        country: input.country,
                                                                        continent: input.continent,
                                                                        longitude: input.longitude,
                                                                        latitude: input.latitude,
                                                                        nearestAirportOverride: value,
                                                                        totalPowerMw: input.totalPowerMw,
                                                                        powerDensity: input.powerDensity,
                                                                        grossBuildingSize: input.grossBuildingSize,
                                                                        grossColocationSpace: input.grossColocationSpace,
                                                                        metaTitle: input.metaTitle,
                                                                        metaDescription: input.metaDescription
                                                                      };
                                                              }), $$event.target.value);
                                                        submitForm();
                                                      })
                                                  }),
                                              fieldError(form.nearestAirportOverrideResult)
                                            ],
                                            className: forms.fieldColumn
                                          })
                                    ],
                                    className: forms.threeInRow
                                  })
                            ]
                          }),
                      className: forms.formContainer
                    }),
                JsxRuntime.jsx(H4.make, {
                      children: "Building Specifications"
                    }),
                JsxRuntime.jsx("div", {
                      children: "Shared of of the building specifications to help describe the location.",
                      className: forms.settingsExplanation
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Form.make, {
                            className: forms.editGeneralInfoForm,
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-location-form--totalPower",
                                                  children: "Total Power (MW)"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "edit-location-form--totalPower",
                                                  value: live === "Live" ? $$location.totalPowerMw : form.input.totalPowerMw,
                                                  placeholder: "Ex. 80",
                                                  disabled: live === "Live" || form.submitting ? true : false,
                                                  className: $$location.totalPowerMw === form.input.totalPowerMw ? "" : forms.fieldChanged,
                                                  onChange: (function ($$event) {
                                                      form.updateTotalPowerMw((function (input, value) {
                                                              return {
                                                                      name: input.name,
                                                                      fullAddress: input.fullAddress,
                                                                      streetAddress1: input.streetAddress1,
                                                                      city: input.city,
                                                                      locationState: input.locationState,
                                                                      zip: input.zip,
                                                                      country: input.country,
                                                                      continent: input.continent,
                                                                      longitude: input.longitude,
                                                                      latitude: input.latitude,
                                                                      nearestAirportOverride: input.nearestAirportOverride,
                                                                      totalPowerMw: value,
                                                                      powerDensity: input.powerDensity,
                                                                      grossBuildingSize: input.grossBuildingSize,
                                                                      grossColocationSpace: input.grossColocationSpace,
                                                                      metaTitle: input.metaTitle,
                                                                      metaDescription: input.metaDescription
                                                                    };
                                                            }), $$event.target.value);
                                                      submitForm();
                                                    })
                                                })
                                          ],
                                          className: forms.fieldColumn
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-provider-form--powerDensity",
                                                  children: "Power Density"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "edit-location-form--powerDensity",
                                                  value: live === "Live" ? $$location.powerDensity : form.input.powerDensity,
                                                  placeholder: "Stock Symbol",
                                                  disabled: live === "Live" || form.submitting ? true : false,
                                                  className: $$location.powerDensity === form.input.powerDensity ? "" : forms.fieldChanged,
                                                  onChange: (function ($$event) {
                                                      form.updatePowerDensity((function (input, value) {
                                                              return {
                                                                      name: input.name,
                                                                      fullAddress: input.fullAddress,
                                                                      streetAddress1: input.streetAddress1,
                                                                      city: input.city,
                                                                      locationState: input.locationState,
                                                                      zip: input.zip,
                                                                      country: input.country,
                                                                      continent: input.continent,
                                                                      longitude: input.longitude,
                                                                      latitude: input.latitude,
                                                                      nearestAirportOverride: input.nearestAirportOverride,
                                                                      totalPowerMw: input.totalPowerMw,
                                                                      powerDensity: value,
                                                                      grossBuildingSize: input.grossBuildingSize,
                                                                      grossColocationSpace: input.grossColocationSpace,
                                                                      metaTitle: input.metaTitle,
                                                                      metaDescription: input.metaDescription
                                                                    };
                                                            }), $$event.target.value);
                                                      submitForm();
                                                    })
                                                })
                                          ],
                                          className: forms.fieldColumn
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-provider-form--grossBuildingSize",
                                                  children: "Gross Building Size (Sqft)"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "edit-location-form--grossBuildingSize",
                                                  value: live === "Live" ? $$location.grossBuildingSize : form.input.grossBuildingSize,
                                                  placeholder: "Stock Symbol",
                                                  disabled: live === "Live" || form.submitting ? true : false,
                                                  className: $$location.grossBuildingSize === form.input.grossBuildingSize ? "" : forms.fieldChanged,
                                                  onChange: (function ($$event) {
                                                      form.updateGrossBuildingSize((function (input, value) {
                                                              return {
                                                                      name: input.name,
                                                                      fullAddress: input.fullAddress,
                                                                      streetAddress1: input.streetAddress1,
                                                                      city: input.city,
                                                                      locationState: input.locationState,
                                                                      zip: input.zip,
                                                                      country: input.country,
                                                                      continent: input.continent,
                                                                      longitude: input.longitude,
                                                                      latitude: input.latitude,
                                                                      nearestAirportOverride: input.nearestAirportOverride,
                                                                      totalPowerMw: input.totalPowerMw,
                                                                      powerDensity: input.powerDensity,
                                                                      grossBuildingSize: value,
                                                                      grossColocationSpace: input.grossColocationSpace,
                                                                      metaTitle: input.metaTitle,
                                                                      metaDescription: input.metaDescription
                                                                    };
                                                            }), $$event.target.value);
                                                      submitForm();
                                                    })
                                                })
                                          ],
                                          className: forms.fieldColumn
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-provider-form--grossColocationSpace",
                                                  children: "Gross Colocation Space (Sqft)"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "edit-location-form--grossBuildingSize",
                                                  value: live === "Live" ? $$location.grossColocationSpace : form.input.grossColocationSpace,
                                                  placeholder: "Stock Symbol",
                                                  disabled: live === "Live" || form.submitting ? true : false,
                                                  className: $$location.grossColocationSpace === form.input.grossColocationSpace ? "" : forms.fieldChanged,
                                                  onChange: (function ($$event) {
                                                      form.updateGrossColocationSpace((function (input, value) {
                                                              return {
                                                                      name: input.name,
                                                                      fullAddress: input.fullAddress,
                                                                      streetAddress1: input.streetAddress1,
                                                                      city: input.city,
                                                                      locationState: input.locationState,
                                                                      zip: input.zip,
                                                                      country: input.country,
                                                                      continent: input.continent,
                                                                      longitude: input.longitude,
                                                                      latitude: input.latitude,
                                                                      nearestAirportOverride: input.nearestAirportOverride,
                                                                      totalPowerMw: input.totalPowerMw,
                                                                      powerDensity: input.powerDensity,
                                                                      grossBuildingSize: input.grossBuildingSize,
                                                                      grossColocationSpace: value,
                                                                      metaTitle: input.metaTitle,
                                                                      metaDescription: input.metaDescription
                                                                    };
                                                            }), $$event.target.value);
                                                      submitForm();
                                                    })
                                                })
                                          ],
                                          className: forms.fieldColumn
                                        })
                                  ],
                                  className: forms.fourInRow
                                })
                          }),
                      className: forms.formContainer
                    }),
                JsxRuntime.jsx(H4.make, {
                      children: "Facility Types"
                    }),
                JsxRuntime.jsx("div", {
                      children: "What type of services and capabilities does this data center offer?",
                      className: forms.settingsExplanation
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Form.make, {
                            className: forms.editGeneralInfoForm,
                            children: JsxRuntime.jsx("div", {
                                  children: Belt_Array.mapWithIndex(Belt_Array.range(0, numOfRows), (function (index, param) {
                                          return JsxRuntime.jsx("div", {
                                                      children: Belt_Array.mapWithIndex(Belt_Array.slice($$location.facilities, Math.imul(index, 3), 3), (function (cIndex, facility) {
                                                              var match = React.useState(function () {
                                                                    return facility.requested;
                                                                  });
                                                              var setRequested = match[1];
                                                              var requested = match[0];
                                                              return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                          id: "facility--" + ID.toString(facility.id),
                                                                          size: "SM",
                                                                          checked: live === "Live" ? facility.live : requested,
                                                                          disabled: live === "Live",
                                                                          containerClassName: forms.directoryServiceOption,
                                                                          onChange: (function (param) {
                                                                              setRequested(function (_prev) {
                                                                                    return !requested;
                                                                                  });
                                                                              $$Promise.wait(Api_DataCenterFacilityLocation.update(facility.facilityLocationId, {
                                                                                        approved: false,
                                                                                        live: facility.live,
                                                                                        requested: !requested
                                                                                      }), (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return updateLocation(x._0);
                                                                                      } else {
                                                                                        return SentryLogger.error1({
                                                                                                    rootModule: "ProviderEditLocationGeneralInfo",
                                                                                                    subModulePath: /* [] */0,
                                                                                                    value: "make",
                                                                                                    fullPath: "ProviderEditLocationGeneralInfo.make"
                                                                                                  }, "ProviderEditFacility::Update::Error", [
                                                                                                    "Error",
                                                                                                    x._0
                                                                                                  ]);
                                                                                      }
                                                                                    }));
                                                                            }),
                                                                          children: JsxRuntime.jsx("div", {
                                                                                children: facility.title,
                                                                                className: Cx.cx([
                                                                                      forms.serviceLabelContainer,
                                                                                      facility.live === requested ? "" : forms.serviceChanged
                                                                                    ])
                                                                              })
                                                                        }, "facility--" + String(cIndex) + "--" + facility.title);
                                                            })),
                                                      className: forms.servicesRow
                                                    }, "services-row" + String(index));
                                        })),
                                  className: forms.facilities
                                })
                          }),
                      className: forms.formContainer
                    }),
                userRole === "Admin" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(H4.make, {
                                children: "SEO Overrides"
                              }),
                          JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs(Form.make, {
                                      className: forms.editGeneralInfoForm,
                                      children: [
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: "location-metaTitle",
                                                      children: "Meta Title"
                                                    }),
                                                JsxRuntime.jsx(TextField.make, {
                                                      id: "location-metaTitle",
                                                      value: live === "Live" ? $$location.metaTitle : form.input.metaTitle,
                                                      placeholder: "Meta Title Override",
                                                      disabled: live === "Live" || form.submitting ? true : false,
                                                      className: $$location.metaTitle === form.input.metaTitle ? "" : forms.fieldChanged,
                                                      onChange: (function ($$event) {
                                                          form.updateMetaTitle((function (input, value) {
                                                                  return {
                                                                          name: input.name,
                                                                          fullAddress: input.fullAddress,
                                                                          streetAddress1: input.streetAddress1,
                                                                          city: input.city,
                                                                          locationState: input.locationState,
                                                                          zip: input.zip,
                                                                          country: input.country,
                                                                          continent: input.continent,
                                                                          longitude: input.longitude,
                                                                          latitude: input.latitude,
                                                                          nearestAirportOverride: input.nearestAirportOverride,
                                                                          totalPowerMw: input.totalPowerMw,
                                                                          powerDensity: input.powerDensity,
                                                                          grossBuildingSize: input.grossBuildingSize,
                                                                          grossColocationSpace: input.grossColocationSpace,
                                                                          metaTitle: value,
                                                                          metaDescription: input.metaDescription
                                                                        };
                                                                }), $$event.target.value);
                                                          submitForm();
                                                        })
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: "This will override the default Meta Title tag for SEO purposes.",
                                                      className: forms.subText
                                                    })
                                              ],
                                              className: forms.fieldRow
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: "location-metaDescription",
                                                      children: "Meta Description"
                                                    }),
                                                JsxRuntime.jsx(TextField.make, {
                                                      id: "location-metaDescription",
                                                      value: live === "Live" ? $$location.metaDescription : form.input.metaDescription,
                                                      placeholder: "Meta Description Override",
                                                      disabled: live === "Live" || form.submitting ? true : false,
                                                      className: $$location.metaDescription === form.input.metaDescription ? "" : forms.fieldChanged,
                                                      onChange: (function ($$event) {
                                                          form.updateMetaDescription((function (input, value) {
                                                                  return {
                                                                          name: input.name,
                                                                          fullAddress: input.fullAddress,
                                                                          streetAddress1: input.streetAddress1,
                                                                          city: input.city,
                                                                          locationState: input.locationState,
                                                                          zip: input.zip,
                                                                          country: input.country,
                                                                          continent: input.continent,
                                                                          longitude: input.longitude,
                                                                          latitude: input.latitude,
                                                                          nearestAirportOverride: input.nearestAirportOverride,
                                                                          totalPowerMw: input.totalPowerMw,
                                                                          powerDensity: input.powerDensity,
                                                                          grossBuildingSize: input.grossBuildingSize,
                                                                          grossColocationSpace: input.grossColocationSpace,
                                                                          metaTitle: input.metaTitle,
                                                                          metaDescription: value
                                                                        };
                                                                }), $$event.target.value);
                                                          submitForm();
                                                        })
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: "This will override the default Meta Description tag for SEO purposes.",
                                                      className: forms.subText
                                                    })
                                              ],
                                              className: forms.fieldRow
                                            })
                                      ]
                                    }),
                                className: forms.formContainer
                              })
                        ]
                      }) : null
              ]
            });
}

var make = ProviderEditLocationGeneralInfo;

export {
  containers ,
  forms ,
  LocationForm ,
  make ,
}
/* containers Not a pure module */
