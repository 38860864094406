// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as IconClose from "../../../../../../styleguide/icons/IconClose.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditInviteManagerForm from "./components/ProviderEditInviteManagerForm.res.js";
import * as ProviderEditInviteManagerScss from "./ProviderEditInviteManager.scss";

var css = ProviderEditInviteManagerScss;

function ProviderEditInviteManager(props) {
  var close = props.close;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: "Invite Manager"
                                  }),
                              JsxRuntime.jsx("h3", {
                                    children: "Send an invitation to a colleague to help manage this provider's profile."
                                  }),
                              JsxRuntime.jsx(ProviderEditInviteManagerForm.make, {
                                    providerId: props.providerId,
                                    providerUsers: props.providerUsers,
                                    close: close,
                                    updateProviderUsers: props.updateProviderUsers
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: css.sideModal,
                      id: "sideModal"
                    })
              ],
              className: css.sideModalContainer,
              id: "inviteModal"
            });
}

var make = ProviderEditInviteManager;

export {
  css ,
  make ,
}
/* css Not a pure module */
