// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as LocationSetting from "../../../models/LocationSetting.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(hidden) {
  return Json_Encode$JsonCombinators.object([[
                "hidden",
                LocationSetting.Hidden.toJson(hidden)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(id, hidden) {
  return Rest.$$fetch("/dashboard/location_settings/" + ID.toString(id), {
              NAME: "Patch",
              VAL: toJson(hidden)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
