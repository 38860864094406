// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateDirectoryProviderServiceRequest from "./requests/UpdateDirectoryProviderServiceRequest.res.js";

var update = UpdateDirectoryProviderServiceRequest.exec;

export {
  update ,
}
/* UpdateDirectoryProviderServiceRequest Not a pure module */
