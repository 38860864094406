// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Toggle from "../../../../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as LocationSetting from "../../../../../../../../models/LocationSetting.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_LocationSetting from "../../../../../../../../api/location-settings/Api_LocationSetting.res.js";
import * as LocationEditToggleButtonsScss from "./LocationEditToggleButtons.scss";

var css = LocationEditToggleButtonsScss;

function LocationEditHiddenButton(props) {
  var updateLocation = props.updateLocation;
  var settings = props.settings;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: LocationSetting.Hidden.toString(LocationSetting.Hidden.fromBool(settings.hidden)),
                            className: Cx.cx([css.inactiveText])
                          }),
                      JsxRuntime.jsx(Toggle.make, {
                            on: settings.hidden,
                            size: "SM",
                            onChange: (function () {
                                var hidden = settings.hidden ? "Shown" : "Hidden";
                                $$Promise.wait(Api_LocationSetting.updateHidden(settings.id, hidden), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return updateLocation(x._0);
                                        } else {
                                          return SentryLogger.error1({
                                                      rootModule: "LocationEditHiddenButton",
                                                      subModulePath: /* [] */0,
                                                      value: "make",
                                                      fullPath: "LocationEditHiddenButton.make"
                                                    }, "UpdateUpdateHidden::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                        }
                                      }));
                              })
                          })
                    ],
                    className: Cx.cx([css.toggleContainer])
                  }),
              className: css.actionButtonsContainer
            });
}

var make = LocationEditHiddenButton;

export {
  css ,
  make ,
}
/* css Not a pure module */
