// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateDataCenterCertificationLocationRequest from "./requests/UpdateDataCenterCertificationLocationRequest.res.js";

var update = UpdateDataCenterCertificationLocationRequest.exec;

export {
  update ,
}
/* UpdateDataCenterCertificationLocationRequest Not a pure module */
