// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../../../../libs/ID.res.js";
import * as Form from "../../../../../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_LocationSetting from "../../../../../../../api/location-settings/Api_LocationSetting.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as LocationEditHiddenButton from "./components/LocationEditHiddenButton.res.js";
import * as ProviderEditFormsScss from "../../../common/ProviderEditForms.scss";

var css = ProviderEditFormsScss;

var validators = {
  overrideCity: undefined
};

function initialFieldsStatuses(_input) {
  return {
          overrideCity: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            overrideCity: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, param, param$1) {
  var match = {
    TAG: "Ok",
    _0: input.overrideCity
  };
  if (match.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              overrideCity: match._0
            },
            fieldsStatuses: {
              overrideCity: {
                TAG: "Dirty",
                _0: match,
                _1: "Hidden"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              overrideCity: {
                TAG: "Dirty",
                _0: match,
                _1: "Hidden"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurOverrideCityField" :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.overrideCity, state.fieldsStatuses.overrideCity, (function (status) {
                          return {
                                  overrideCity: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateOverrideCityField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.overrideCity, (function (status) {
                                    return {
                                            overrideCity: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                overrideCity: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                overrideCity: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateOverrideCity: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateOverrideCityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurOverrideCity: (function () {
              dispatch("BlurOverrideCityField");
            }),
          overrideCityResult: Formality.exposeFieldResult(state.fieldsStatuses.overrideCity),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.overrideCity;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var LocationSettingForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditLocationSettings(props) {
  var updateLocation = props.updateLocation;
  var $$location = props.location;
  var initialInput = React.useMemo((function () {
          return {
                  overrideCity: $$location.settings.overrideCity
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                    field: "OverrideCity",
                    value: false,
                    overrideCity: output.overrideCity
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditLocationSettings",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditLocationSettings.make"
                              }, "LocationSettings::Update::Error", [
                                "Error",
                                x._0
                              ]);
                  }
                  var res = x._0;
                  updateLocation(res);
                  cb.notifyOnSuccess({
                        overrideCity: res.settings.overrideCity
                      });
                }));
        }));
  var submitForm = Debounce.make(3000, (function () {
          form.submit();
        }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H3.make, {
                      children: "Select Location Settings"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.settingsTitle,
                              children: "Directory Display Settings"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Choose from the following settings. Changes are saved automatically.\n           Hidden locations can only be revealed by admins. Sponsored locations\n           appear first in directory search results.",
                              className: css.settingsExplanation
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "edit-provider-form--master",
                                            children: "Hidden"
                                          }),
                                      JsxRuntime.jsx(LocationEditHiddenButton.make, {
                                            settings: $$location.settings,
                                            updateLocation: updateLocation
                                          })
                                    ],
                                    className: css.fieldColumn
                                  }),
                              className: css.toggleRow
                            })
                      ],
                      className: css.settings
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.settingsTitle,
                              children: "Sponsorships"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "When a box is checked below, an active location (with an active provider) will\n           be listed first in location index pages.",
                              className: css.settingsExplanation
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "sponsoredGlobal--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.sponsoredGlobal,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "SponsoredGlobal",
                                                    value: !$$location.settings.sponsoredGlobal,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Global"
                                          })
                                    }),
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "sponsoredCountry--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.sponsoredCountry,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "SponsoredCountry",
                                                    value: !$$location.settings.sponsoredCountry,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Countries"
                                          })
                                    }),
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "sponsoredState--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.sponsoredState,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "SponsoredState",
                                                    value: !$$location.settings.sponsoredState,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "States"
                                          })
                                    })
                              ],
                              className: css.servicesRow
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                    id: "sponsoredCity--" + ID.toString($$location.settings.id),
                                    size: "SM",
                                    checked: $$location.settings.sponsoredCity,
                                    containerClassName: css.settingsServiceOption,
                                    onChange: (function (param) {
                                        $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                  field: "SponsoredCity",
                                                  value: !$$location.settings.sponsoredCity,
                                                  overrideCity: undefined
                                                }), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return updateLocation(x._0);
                                                } else {
                                                  return SentryLogger.error1({
                                                              rootModule: "ProviderEditLocationSettings",
                                                              subModulePath: /* [] */0,
                                                              value: "make",
                                                              fullPath: "ProviderEditLocationSettings.make"
                                                            }, "LocationSettings::Update::Error", [
                                                              "Error",
                                                              x._0
                                                            ]);
                                                }
                                              }));
                                      }),
                                    children: JsxRuntime.jsx("div", {
                                          children: "Cities"
                                        })
                                  }),
                              className: css.servicesRow
                            })
                      ],
                      className: css.settings
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.settingsTitle,
                              children: "Project"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "When a box is checked below, an active location (with an active provider that has an agreement)\n           will be available for selection in the correspond project platform.\n           Changes are saved automatically.",
                              className: css.settingsExplanation
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "colocationProjects--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.colocationProjects,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "ColocationProjects",
                                                    value: !$$location.settings.colocationProjects,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Colocation"
                                          })
                                    }),
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "bareMetalProjects--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.bareMetalProjects,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "BareMetalProjects",
                                                    value: !$$location.settings.bareMetalProjects,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Bare Metal"
                                          })
                                    })
                              ],
                              className: css.servicesRow
                            })
                      ],
                      className: css.settings
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.settingsTitle,
                              children: "Directories"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "When a box is checked below, an active location (with an active provider) will be featured the corresponding directory. Changes are saved automatically.",
                              className: css.settingsExplanation
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "colocationDirectory--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.colocationDirectory,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "ColocationDirectory",
                                                    value: !$$location.settings.colocationDirectory,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Colocation"
                                          })
                                    }),
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "bareMetalDirectory--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.bareMetalDirectory,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "BareMetalDirectory",
                                                    value: !$$location.settings.bareMetalDirectory,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Bare Metal"
                                          })
                                    }),
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "internetDirectory--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.internetDirectory,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "InternetDirectory",
                                                    value: !$$location.settings.internetDirectory,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Internet"
                                          })
                                    })
                              ],
                              className: css.servicesRow
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "networkDirectory--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.networkDirectory,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "NetworkDirectory",
                                                    value: !$$location.settings.networkDirectory,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Network Directory"
                                          })
                                    }),
                                JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                      id: "cloudDirectory--" + ID.toString($$location.settings.id),
                                      size: "SM",
                                      checked: $$location.settings.cloudDirectory,
                                      containerClassName: css.settingsServiceOption,
                                      onChange: (function (param) {
                                          $$Promise.wait(Api_LocationSetting.update($$location.settings.id, {
                                                    field: "CloudDirectory",
                                                    value: !$$location.settings.cloudDirectory,
                                                    overrideCity: undefined
                                                  }), (function (x) {
                                                  if (x.TAG === "Ok") {
                                                    return updateLocation(x._0);
                                                  } else {
                                                    return SentryLogger.error1({
                                                                rootModule: "ProviderEditLocationSettings",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "ProviderEditLocationSettings.make"
                                                              }, "LocationSettings::Update::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                }));
                                        }),
                                      children: JsxRuntime.jsx("div", {
                                            children: "Cloud"
                                          })
                                    })
                              ],
                              className: css.servicesRow
                            })
                      ],
                      className: css.settings
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.settingsTitle,
                              children: "Other Settings"
                            }),
                        JsxRuntime.jsx(Form.make, {
                              className: css.editGeneralInfoForm,
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "location-metaTitle",
                                            children: "Override City"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "location-metaTitle",
                                            value: form.input.overrideCity,
                                            placeholder: "City Override",
                                            disabled: form.submitting,
                                            onChange: (function ($$event) {
                                                form.updateOverrideCity((function (_input, value) {
                                                        return {
                                                                overrideCity: value
                                                              };
                                                      }), $$event.target.value);
                                                submitForm();
                                              })
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "This will override the default city when searching locations in a directory.\n              (Ex. Override \"Aurora\" with \"Denver\".)",
                                            className: css.subText
                                          })
                                    ],
                                    className: css.fieldRow
                                  })
                            })
                      ],
                      className: css.formContainer
                    })
              ],
              className: css.settingsContainer
            });
}

var make = ProviderEditLocationSettings;

export {
  css ,
  LocationSettingForm ,
  make ,
}
/* css Not a pure module */
