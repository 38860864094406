// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateDataCenterFacilityLocationRequest from "./requests/UpdateDataCenterFacilityLocationRequest.res.js";

var update = UpdateDataCenterFacilityLocationRequest.exec;

export {
  update ,
}
/* UpdateDataCenterFacilityLocationRequest Not a pure module */
