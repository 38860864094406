// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProviderEditApi from "./components/api/ProviderEditApi.res.js";
import * as ProviderEditTab from "./ProviderEditTab.res.js";
import * as Routes_Provider from "../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditPeople from "./components/provider-users/ProviderEditPeople.res.js";
import * as ProviderEditSettings from "./components/settings/ProviderEditSettings.res.js";
import * as ProviderEditLocations from "./components/locations/ProviderEditLocations.res.js";
import * as ProviderEditTabsScss from "./ProviderEditTabs.scss";
import * as ProviderEditProviderDetails from "./components/provider-details/ProviderEditProviderDetails.res.js";

var css = ProviderEditTabsScss;

function ProviderEditTabs(props) {
  var children = props.children;
  var selectedOption = props.selectedOption;
  var updateProvider = props.updateProvider;
  var userRole = props.userRole;
  var live = props.live;
  var provider = props.provider;
  var initialState = React.useMemo((function () {
          return {
                  selectedOption: selectedOption
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    selectedOption: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.selectedOption;
  var match$2 = state.selectedOption;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              Belt_Array.mapWithIndex(ProviderEditTab.Nav.Tab.tabs, (function (index, tab) {
                                      return JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(Link.make, {
                                                        href: "#",
                                                        className: css.linkItem,
                                                        children: tab.title
                                                      }),
                                                  className: Cx.cx([
                                                        css.linkContainer,
                                                        state.selectedOption === tab.tab ? css.underlinedLink : ""
                                                      ]),
                                                  onClick: (function (param) {
                                                      dispatch({
                                                            TAG: "ToggleOptions",
                                                            _0: tab.tab
                                                          });
                                                    })
                                                }, String(index));
                                    })),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Button.AsLink.make, {
                                          href: Routes_Provider.Dashboard.index,
                                          size: "SM",
                                          color: "Gray",
                                          children: "Profiles & Listings"
                                        }),
                                    className: css.adminLink
                                  })
                            ],
                            className: css.barContainer
                          }),
                      className: css.barWrapper
                    }),
                match$1 === "Settings" ? JsxRuntime.jsx(ProviderEditSettings.make, {
                        provider: provider,
                        live: live,
                        userRole: userRole,
                        updateProvider: updateProvider
                      }) : (
                    match$1 === "People" ? JsxRuntime.jsx(ProviderEditPeople.make, {
                            provider: provider,
                            providerUsers: props.providerUsers,
                            updateProviderUsers: props.updateProviderUsers
                          }) : (
                        match$1 === "ProviderDetails" ? JsxRuntime.jsx(ProviderEditProviderDetails.make, {
                                provider: provider,
                                live: live,
                                userRole: userRole,
                                updateProvider: updateProvider,
                                updateEditStatus: props.updateEditStatus
                              }) : (
                            match$1 === "Api" ? JsxRuntime.jsx(ProviderEditApi.make, {
                                    provider: provider,
                                    live: live,
                                    apiKeys: props.apiKeys,
                                    updateProvider: updateProvider,
                                    updateApiKeys: props.updateApiKeys
                                  }) : JsxRuntime.jsx(ProviderEditLocations.make, {
                                    provider: provider,
                                    userRole: userRole,
                                    locationId: props.locationId
                                  })
                          )
                      )
                  ),
                (match$2 === "Api" || match$2 === "ProviderDetails" || match$2 === "People" || match$2 === "Settings") && children !== undefined ? Caml_option.valFromOption(children) : null
              ]
            });
}

var make = ProviderEditTabs;

export {
  css ,
  make ,
}
/* css Not a pure module */
