// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditStickyBarScss from "../../ProviderEditStickyBar.scss";

var css = ProviderEditStickyBarScss;

function ProviderEditApproveChanges(props) {
  var tmp;
  switch (props.editStatus) {
    case "Updating" :
        tmp = true;
        break;
    case "Editing" :
    case "Error" :
        tmp = false;
        break;
    
  }
  var tmp$1;
  if (props.approved) {
    tmp$1 = "Approved";
  } else {
    switch (props.userRole) {
      case "Admin" :
          tmp$1 = "Approve";
          break;
      case "Visitor" :
          tmp$1 = null;
          break;
      default:
        tmp$1 = "Waiting Approval";
    }
  }
  return JsxRuntime.jsx(Button.make, {
              size: "MD",
              color: "Teal",
              visuallyDisabled: props.disabled,
              busy: tmp,
              className: css.approveButton,
              onClick: props.approve,
              children: tmp$1
            });
}

var make = ProviderEditApproveChanges;

export {
  css ,
  make ,
}
/* css Not a pure module */
