// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "id",
                ID.toJson(req.id)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(featureId) {
  var url = "/dashboard/location_features/" + ID.toString(featureId.id);
  return Rest.$$fetch(url, {
              NAME: "Delete",
              VAL: toJson(featureId)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
