// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as ID from "../../../../../../../libs/ID.res.js";
import * as Video from "../../../../../../../models/Video.res.js";
import * as React from "react";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Location from "../../../../../../../api/locations/Api_Location.res.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as Globals from "Common/globals";
import * as CloudinaryWidget from "../../../../../../../bindings/CloudinaryWidget.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditLocationImageForm from "./ProviderEditLocationImageForm.res.js";
import * as ProviderEditLocationVideoForm from "./ProviderEditLocationVideoForm.res.js";
import * as ReactCloudinaryUploadWidget from "react-cloudinary-upload-widget";
import * as ProviderEditLocationAssetsScss from "./ProviderEditLocationAssets.scss";
import * as ProviderEditLocationAssetsTabVideoLinkForm from "./ProviderEditLocationAssetsTabVideoLinkForm.res.js";

var css = ProviderEditLocationAssetsScss;

function reducer(state, action) {
  switch (action.TAG) {
    case "ToggleDestroyConfirm" :
        var id = action._1;
        if (action._0 === "Image") {
          return {
                  destroyImageConfirm: !state.destroyImageConfirm,
                  destroyVideoConfirm: state.destroyVideoConfirm,
                  currentVideo: state.currentVideo,
                  currentImage: id,
                  timeout: state.timeout,
                  videos: state.videos,
                  newVideos: state.newVideos,
                  savedVideos: state.savedVideos,
                  location: state.location
                };
        } else {
          return {
                  destroyImageConfirm: state.destroyImageConfirm,
                  destroyVideoConfirm: !state.destroyVideoConfirm,
                  currentVideo: id,
                  currentImage: state.currentImage,
                  timeout: state.timeout,
                  videos: state.videos,
                  newVideos: state.newVideos,
                  savedVideos: state.savedVideos,
                  location: state.location
                };
        }
    case "SetTimeout" :
        return {
                destroyImageConfirm: state.destroyImageConfirm,
                destroyVideoConfirm: state.destroyVideoConfirm,
                currentVideo: state.currentVideo,
                currentImage: state.currentImage,
                timeout: action._0,
                videos: state.videos,
                newVideos: state.newVideos,
                savedVideos: state.savedVideos,
                location: state.location
              };
    case "StoreAddedLinkedVideo" :
        return {
                destroyImageConfirm: state.destroyImageConfirm,
                destroyVideoConfirm: state.destroyVideoConfirm,
                currentVideo: state.currentVideo,
                currentImage: state.currentImage,
                timeout: state.timeout,
                videos: state.videos,
                newVideos: state.newVideos,
                savedVideos: state.savedVideos,
                location: action._0
              };
    
  }
}

function ProviderEditLocationAssets(props) {
  var updateLocation = props.updateLocation;
  var live = props.live;
  var $$location = props.location;
  var videos = Video.normalize($$location.videos);
  var initialState_videos = [];
  var initialState_newVideos = {
    index: [],
    dict: undefined
  };
  var initialState_savedVideos = {
    index: videos.index,
    dict: Belt_Map.map(videos.dict, (function (video) {
            return [
                    video,
                    "StandBy"
                  ];
          }))
  };
  var initialState = {
    destroyImageConfirm: false,
    destroyVideoConfirm: false,
    currentVideo: undefined,
    currentImage: undefined,
    timeout: undefined,
    videos: initialState_videos,
    newVideos: initialState_newVideos,
    savedVideos: initialState_savedVideos,
    location: $$location
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var updateTimeout = function (timeout) {
    var match = state.timeout;
    if (match !== undefined) {
      clearTimeout(Belt_Option.getExn(state.timeout));
      return dispatch({
                  TAG: "SetTimeout",
                  _0: Caml_option.some(timeout)
                });
    } else {
      return dispatch({
                  TAG: "SetTimeout",
                  _0: Caml_option.some(timeout)
                });
    }
  };
  var match$1 = $$location.images;
  var match$2 = $$location.videos;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Add Images & Videos"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      "Click to Upload Images and Videos",
                                      JsxRuntime.jsx(ReactCloudinaryUploadWidget.Widget, {
                                            sources: ["local"],
                                            cloudName: Globals.CloudinaryCloudName,
                                            apiKey: Globals.CloudinaryAPIKey,
                                            uploadPreset: Globals.CloudinaryUploadPreset,
                                            accepts: "application/json",
                                            contentType: "application/json",
                                            folder: "locations/" + $$location.slug + "/images",
                                            style: {
                                              color: "white",
                                              border: "1px solid",
                                              borderColor: "#18c3bb",
                                              borderRadius: "3px",
                                              fontWeight: "600",
                                              backgroundColor: "#18c3bb",
                                              padding: "8px 20px",
                                              fontSize: "14px"
                                            },
                                            cropping: false,
                                            multiple: true,
                                            onSuccess: (function (result) {
                                                console.log("RESULT:", CloudinaryWidget.fromJson(result));
                                                var content = CloudinaryWidget.fromJson(result);
                                                var match = content.info.format;
                                                var exit = 0;
                                                switch (match) {
                                                  case "jpeg" :
                                                  case "jpg" :
                                                  case "png" :
                                                      exit = 2;
                                                      break;
                                                  case "avi" :
                                                  case "mov" :
                                                  case "mp4" :
                                                  case "wmv" :
                                                      exit = 1;
                                                      break;
                                                  default:
                                                    exit = 2;
                                                }
                                                switch (exit) {
                                                  case 1 :
                                                      return $$Promise.wait(Api_Location.createVideo($$location.id, {
                                                                      video: content
                                                                    }), (function (x) {
                                                                    if (x.TAG === "Ok") {
                                                                      return updateLocation(x._0);
                                                                    } else {
                                                                      return SentryLogger.error1({
                                                                                  rootModule: "ProviderEditLocationAssets",
                                                                                  subModulePath: /* [] */0,
                                                                                  value: "make",
                                                                                  fullPath: "ProviderEditLocationAssets.make"
                                                                                }, "CreateLocationVideo::Error", [
                                                                                  "Error",
                                                                                  x._0
                                                                                ]);
                                                                    }
                                                                  }));
                                                  case 2 :
                                                      return $$Promise.wait(Api_Location.createImage($$location.id, {
                                                                      image: content
                                                                    }), (function (x) {
                                                                    if (x.TAG === "Ok") {
                                                                      return updateLocation(x._0);
                                                                    } else {
                                                                      return SentryLogger.error1({
                                                                                  rootModule: "ProviderEditLocationAssets",
                                                                                  subModulePath: /* [] */0,
                                                                                  value: "make",
                                                                                  fullPath: "ProviderEditLocationAssets.make"
                                                                                }, "CreateLocationImage::Error", [
                                                                                  "Error",
                                                                                  x._0
                                                                                ]);
                                                                    }
                                                                  }));
                                                  
                                                }
                                              })
                                          })
                                    ],
                                    className: css.uploaderInner
                                  }),
                              className: css.uploader
                            })
                      ],
                      className: css.uploaderContainer
                    }),
                JsxRuntime.jsx(ProviderEditLocationAssetsTabVideoLinkForm.make, {
                      locationId: $$location.id,
                      videos: state.videos,
                      addLinkedVideo: (function ($$location) {
                          dispatch({
                                TAG: "StoreAddedLinkedVideo",
                                _0: $$location
                              });
                        }),
                      updateLocation: updateLocation
                    }),
                match$1.length !== 0 ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(H3.make, {
                                children: "Images"
                              }),
                          JsxRuntime.jsx("div", {
                                children: Belt_Array.mapWithIndex($$location.images, (function (index, image) {
                                        return JsxRuntime.jsx(ProviderEditLocationImageForm.make, {
                                                    location: $$location,
                                                    image: image,
                                                    live: live,
                                                    updateLocation: updateLocation,
                                                    toggleDestroy: (function (id) {
                                                        dispatch({
                                                              TAG: "ToggleDestroyConfirm",
                                                              _0: "Image",
                                                              _1: Caml_option.some(id)
                                                            });
                                                      }),
                                                    updateTimeout: (function (timeout) {
                                                        updateTimeout(timeout);
                                                      })
                                                  }, "image--" + String(index) + "--" + ID.toString(image.id));
                                      })),
                                className: css.imagesGrid
                              })
                        ],
                        className: css.imagesContainer
                      }) : null,
                match$2.length !== 0 ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(H3.make, {
                                children: "Videos"
                              }),
                          JsxRuntime.jsx("div", {
                                children: Belt_Array.mapWithIndex($$location.videos, (function (index, video) {
                                        return JsxRuntime.jsx(ProviderEditLocationVideoForm.make, {
                                                    location: $$location,
                                                    video: video,
                                                    live: live,
                                                    updateLocation: updateLocation,
                                                    toggleDestroy: (function (id) {
                                                        dispatch({
                                                              TAG: "ToggleDestroyConfirm",
                                                              _0: "Video",
                                                              _1: Caml_option.some(id)
                                                            });
                                                      }),
                                                    updateTimeout: (function (timeout) {
                                                        updateTimeout(timeout);
                                                      })
                                                  }, "video--" + String(index) + "--" + ID.toString(video.id));
                                      })),
                                className: css.videosGrid
                              })
                        ],
                        className: css.videosContainer
                      }) : null,
                state.destroyImageConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.deleteConfirmationText
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Image will be deleted permanently.",
                                      className: css.deleteConfirmationMessage
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleDestroyConfirm",
                                                        _0: "Image",
                                                        _1: undefined
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var locationId = $$location.id;
                                                  var imageId = Belt_Option.getExn(state.currentImage);
                                                  $$Promise.wait(Api_Location.deleteImage(locationId, imageId), (function (x) {
                                                          if (x.TAG !== "Ok") {
                                                            return SentryLogger.error1({
                                                                        rootModule: "ProviderEditLocationAssets",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "ProviderEditLocationAssets.make"
                                                                      }, "DeleteLocationImage::Error", [
                                                                        "Error",
                                                                        x._0
                                                                      ]);
                                                          }
                                                          updateLocation(x._0);
                                                          dispatch({
                                                                TAG: "ToggleDestroyConfirm",
                                                                _0: "Image",
                                                                _1: undefined
                                                              });
                                                        }));
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.deleteConfirmationButtons
                                    })
                              ],
                              className: css.deleteConfirmationDialog
                            }),
                        className: css.deleteConfirmationOverlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null,
                state.destroyVideoConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.deleteConfirmationText
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Image will be deleted permanently.",
                                      className: css.deleteConfirmationMessage
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleDestroyConfirm",
                                                        _0: "Video",
                                                        _1: undefined
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var locationId = $$location.id;
                                                  var videoId = Belt_Option.getExn(state.currentVideo);
                                                  $$Promise.wait(Api_Location.deleteVideo(locationId, videoId), (function (x) {
                                                          if (x.TAG !== "Ok") {
                                                            return SentryLogger.error1({
                                                                        rootModule: "ProviderEditLocationAssets",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "ProviderEditLocationAssets.make"
                                                                      }, "DeleteLocationVideo::Error", [
                                                                        "Error",
                                                                        x._0
                                                                      ]);
                                                          }
                                                          updateLocation(x._0);
                                                          dispatch({
                                                                TAG: "ToggleDestroyConfirm",
                                                                _0: "Video",
                                                                _1: undefined
                                                              });
                                                        }));
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.deleteConfirmationButtons
                                    })
                              ],
                              className: css.deleteConfirmationDialog
                            }),
                        className: css.deleteConfirmationOverlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null
              ],
              className: css.container
            });
}

var make = ProviderEditLocationAssets;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
