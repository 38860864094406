// Generated by ReScript, PLEASE EDIT WITH CARE


function toString(continent) {
  switch (continent) {
    case "Europe" :
        return "Europe";
    case "Asia" :
        return "Asia";
    case "NorthAmerica" :
        return "North America";
    case "Africa" :
        return "Africa";
    case "Antarctica" :
        return "Antarctica";
    case "Australia" :
        return "Australia";
    case "SouthAmerica" :
        return "South America";
    case "Unknown" :
        return "Not Found";
    
  }
}

function fromString(value) {
  var value$1 = value.toLowerCase();
  switch (value$1) {
    case "africa" :
        return "Africa";
    case "antarctica" :
        return "Antarctica";
    case "asia" :
        return "Asia";
    case "australia" :
        return "Australia";
    case "europe" :
        return "Europe";
    case "north america" :
        return "NorthAmerica";
    case "south america" :
        return "SouthAmerica";
    default:
      return "Unknown";
  }
}

function fromCountryCode(code) {
  switch (code) {
    case "AQ" :
    case "GS" :
    case "TF" :
        return "Antarctica";
    case "AD" :
    case "AL" :
    case "AT" :
    case "BA" :
    case "BE" :
    case "BG" :
    case "BY" :
    case "CH" :
    case "CZ" :
    case "DE" :
    case "DK" :
    case "EE" :
    case "ES" :
    case "FI" :
    case "FO" :
    case "FR" :
    case "GB" :
    case "GG" :
    case "GI" :
    case "GR" :
    case "HR" :
    case "HU" :
    case "IE" :
    case "IM" :
    case "IS" :
    case "IT" :
    case "JE" :
    case "LI" :
    case "LT" :
    case "LU" :
    case "LV" :
    case "MC" :
    case "MD" :
    case "ME" :
    case "MK" :
    case "MT" :
    case "NL" :
    case "NO" :
    case "PL" :
    case "PT" :
    case "RO" :
    case "RS" :
    case "RU" :
    case "SE" :
    case "SI" :
    case "SJ" :
    case "SK" :
    case "SM" :
    case "UA" :
        return "Europe";
    case "AR" :
    case "BO" :
    case "BR" :
    case "CL" :
    case "CO" :
    case "EC" :
    case "FK" :
    case "GF" :
    case "GY" :
    case "PE" :
    case "PY" :
    case "SR" :
    case "UY" :
    case "VE" :
        return "SouthAmerica";
    case "AG" :
    case "AI" :
    case "AN" :
    case "AW" :
    case "BB" :
    case "BM" :
    case "BS" :
    case "BZ" :
    case "CA" :
    case "CR" :
    case "CU" :
    case "DM" :
    case "DO" :
    case "GD" :
    case "GL" :
    case "GP" :
    case "GT" :
    case "HN" :
    case "HT" :
    case "JM" :
    case "KN" :
    case "KY" :
    case "LC" :
    case "MQ" :
    case "MS" :
    case "MX" :
    case "NI" :
    case "PA" :
    case "PM" :
    case "PR" :
    case "SV" :
    case "TC" :
    case "TT" :
    case "US" :
    case "VC" :
    case "VG" :
    case "VI" :
        return "NorthAmerica";
    case "AS" :
    case "AU" :
    case "CK" :
    case "FJ" :
    case "FM" :
    case "GU" :
    case "KI" :
    case "MH" :
    case "MP" :
    case "NC" :
    case "NF" :
    case "NR" :
    case "NU" :
    case "NZ" :
    case "PF" :
    case "PG" :
    case "PN" :
    case "PW" :
    case "SB" :
    case "TK" :
    case "TO" :
    case "TV" :
    case "VU" :
    case "WF" :
    case "WS" :
        return "Australia";
    case "AE" :
    case "AF" :
    case "AM" :
    case "AZ" :
    case "BD" :
    case "BH" :
    case "BN" :
    case "BT" :
    case "CC" :
    case "CN" :
    case "CX" :
    case "CY" :
    case "GE" :
    case "HK" :
    case "ID" :
    case "IL" :
    case "IN" :
    case "IO" :
    case "IQ" :
    case "IR" :
    case "JO" :
    case "JP" :
    case "KG" :
    case "KH" :
    case "KP" :
    case "KR" :
    case "KW" :
    case "KZ" :
    case "LA" :
    case "LB" :
    case "LK" :
    case "MM" :
    case "MN" :
    case "MO" :
    case "MV" :
    case "MY" :
    case "NP" :
    case "OM" :
    case "PH" :
    case "PK" :
    case "PS" :
    case "QA" :
    case "SA" :
    case "SG" :
    case "SY" :
    case "TH" :
    case "TJ" :
    case "TM" :
    case "TR" :
    case "TW" :
    case "UZ" :
    case "VN" :
    case "YE" :
        return "Asia";
    case "AO" :
    case "BF" :
    case "BI" :
    case "BJ" :
    case "BW" :
    case "CD" :
    case "CF" :
    case "CG" :
    case "CI" :
    case "CM" :
    case "CV" :
    case "DJ" :
    case "DZ" :
    case "EG" :
    case "EH" :
    case "ER" :
    case "ET" :
    case "GA" :
    case "GH" :
    case "GM" :
    case "GN" :
    case "GQ" :
    case "GW" :
    case "KE" :
    case "KM" :
    case "LR" :
    case "LS" :
    case "LY" :
    case "MA" :
    case "MG" :
    case "ML" :
    case "MR" :
    case "MU" :
    case "MW" :
    case "MZ" :
    case "NA" :
    case "NE" :
    case "NG" :
    case "RE" :
    case "RW" :
    case "SC" :
    case "SD" :
    case "SH" :
    case "SL" :
    case "SN" :
    case "SO" :
    case "ST" :
    case "SZ" :
    case "TD" :
    case "TG" :
    case "TN" :
    case "TZ" :
    case "UG" :
    case "YT" :
    case "ZA" :
    case "ZM" :
    case "ZW" :
        return "Africa";
    default:
      return "Unknown";
  }
}

export {
  toString ,
  fromString ,
  fromCountryCode ,
}
/* No side effect */
