// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as FetchProviderUsersRequest from "./FetchProviderUsersRequest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "first_name",
                req.firstName
              ],
              [
                "last_name",
                req.lastName
              ],
              [
                "email",
                req.email
              ],
              [
                "provider_role",
                req.providerRole
              ],
              [
                "page",
                req.page
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(providerId, body) {
  return Rest.$$fetch("/dashboard/providers/" + ID.toString(providerId) + "/provider_users", {
              NAME: "Post",
              VAL: toJson(body)
            }, {
              NAME: "Json",
              VAL: FetchProviderUsersRequest.Res.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
