// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../../../api/Api.res.js";
import * as Form from "../../../../../../styleguide/forms/Form/Form.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../../styleguide/forms/Label/Label.res.js";
import * as Regex from "../../../../../../libs/Regex.res.js";
import * as Video from "../../../../../../models/Video.res.js";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as IconOk from "../../../../../../styleguide/icons/IconOk.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as TextField from "../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditAssetsScss from "./ProviderEditAssets.scss";

var css = ProviderEditAssetsScss;

function parseUrl(url) {
  if (url === "") {
    return {
            TAG: "Error",
            _0: "NoUrl"
          };
  } else {
    return Belt_Option.getWithDefault(Belt_Option.map(Caml_option.null_to_opt(Regex.youtube().exec(url)), (function (res) {
                      var match = Belt_Option.flatMap(Belt_Array.get(res, 0), (function (prim) {
                              if (prim == null) {
                                return ;
                              } else {
                                return Caml_option.some(prim);
                              }
                            }));
                      var match$1 = Belt_Option.flatMap(Belt_Array.get(res, 1), (function (prim) {
                              if (prim == null) {
                                return ;
                              } else {
                                return Caml_option.some(prim);
                              }
                            }));
                      if (match !== undefined && match$1 !== undefined) {
                        return {
                                TAG: "Ok",
                                _0: [
                                  "YouTube",
                                  [
                                    match,
                                    match$1
                                  ]
                                ]
                              };
                      } else {
                        return {
                                TAG: "Error",
                                _0: "BadUrl"
                              };
                      }
                    })), Belt_Option.getWithDefault(Belt_Option.map(Caml_option.null_to_opt(Regex.vimeo().exec(url)), (function (res) {
                          var match = Belt_Option.flatMap(Belt_Array.get(res, 0), (function (prim) {
                                  if (prim == null) {
                                    return ;
                                  } else {
                                    return Caml_option.some(prim);
                                  }
                                }));
                          var match$1 = Belt_Option.flatMap(Belt_Array.get(res, 4), (function (prim) {
                                  if (prim == null) {
                                    return ;
                                  } else {
                                    return Caml_option.some(prim);
                                  }
                                }));
                          if (match !== undefined && match$1 !== undefined) {
                            return {
                                    TAG: "Ok",
                                    _0: [
                                      "Vimeo",
                                      [
                                        match,
                                        match$1
                                      ]
                                    ]
                                  };
                          } else {
                            return {
                                    TAG: "Error",
                                    _0: "BadUrl"
                                  };
                          }
                        })), {
                    TAG: "Error",
                    _0: "BadUrl"
                  }));
  }
}

var initialState = {
  status: "Editing",
  input: "",
  result: undefined,
  submissionError: false
};

function ProviderEditAssetsTabVideoLinkForm(props) {
  var updateProvider = props.updateProvider;
  var addLinkedVideo = props.addLinkedVideo;
  var videos = props.videos;
  var providerId = props.providerId;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Submit" :
                  var error = Belt_Result.flatMap(parseUrl(state.input), (function (param) {
                          var match = param[1];
                          var publicId = match[1];
                          if (Belt_Array.some(videos, (function (video) {
                                    return video.NAME === "Saved" ? video.VAL[0].publicId === publicId : false;
                                  }))) {
                            return {
                                    TAG: "Error",
                                    _0: "DupUrl"
                                  };
                          } else {
                            return {
                                    TAG: "Ok",
                                    _0: [
                                      param[0],
                                      [
                                        match[0],
                                        publicId
                                      ]
                                    ]
                                  };
                          }
                        }));
                  if (error.TAG !== "Ok") {
                    return {
                            TAG: "Update",
                            _0: {
                              status: "EditingAfterFailedSubmission",
                              input: state.input,
                              result: {
                                TAG: "Error",
                                _0: error._0
                              },
                              submissionError: state.submissionError
                            }
                          };
                  }
                  var match = error._0;
                  var match$1 = match[1];
                  var publicId = match$1[1];
                  var url = match$1[0];
                  var source = match[0];
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            input: state.input,
                            result: {
                              TAG: "Ok",
                              _0: [
                                source,
                                [
                                  url,
                                  publicId
                                ]
                              ]
                            },
                            submissionError: state.submissionError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.createProviderVideo(providerId, source === "YouTube" ? Video.Interim.fromYouTube("https://" + url, publicId, "100", "100") : Video.Interim.fromVimeo("https://" + url, publicId, "100", "100")), (function (x) {
                                      if (x.TAG === "Ok") {
                                        var res = x._0;
                                        updateProvider(res);
                                        return dispatch({
                                                    TAG: "SucceedSubmission",
                                                    _0: res
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditAssetsTabVideoLinkForm",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditAssetsTabVideoLinkForm.make"
                                          }, "ProviderEditAssetsTabVideoLinkForm::Submit::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailSubmission");
                                    }));
                            })
                        };
              case "ResetSubmittedStatus" :
                  var match$2 = state.status;
                  if (match$2 === "Submitted") {
                    return {
                            TAG: "Update",
                            _0: {
                              status: "Editing",
                              input: state.input,
                              result: state.result,
                              submissionError: state.submissionError
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "FailSubmission" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "EditingAfterFailedSubmission",
                            input: state.input,
                            result: state.result,
                            submissionError: true
                          }
                        };
              
            }
          } else {
            if (action.TAG === "UpdateInput") {
              var value = action._0;
              var match$3 = state.status;
              switch (match$3) {
                case "EditingAfterFailedSubmission" :
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              input: value,
                              result: parseUrl(value),
                              submissionError: false
                            }
                          };
                case "Submitting" :
                    return "NoUpdate";
                case "Editing" :
                case "Submitted" :
                    break;
                
              }
              return {
                      TAG: "Update",
                      _0: {
                        status: state.status,
                        input: value,
                        result: state.result,
                        submissionError: state.submissionError
                      }
                    };
            }
            var video = action._0;
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: "Submitted",
                      input: "",
                      result: undefined,
                      submissionError: false
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        addLinkedVideo(video);
                        setTimeout((function () {
                                dispatch("ResetSubmittedStatus");
                              }), 5000);
                      })
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  var tmp;
  tmp = match$1 === "Submitting" ? true : false;
  var match$2 = state.status;
  var tmp$1;
  tmp$1 = match$2 === "Submitted" ? JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(IconOk.make, {
                  size: "MD",
                  color: "Teal"
                }),
            "Video saved"
          ],
          className: css.assetsVideoLinkFormSubmitted
        }) : null;
  var match$3 = state.result;
  var tmp$2;
  if (match$3 !== undefined && match$3.TAG !== "Ok") {
    var tmp$3;
    if (state.submissionError) {
      tmp$3 = "Something went wrong during form submission";
    } else {
      switch (match$3._0) {
        case "NoUrl" :
            tmp$3 = "Url is required";
            break;
        case "BadUrl" :
            tmp$3 = "This is not valid YouTube url";
            break;
        case "DupUrl" :
            tmp$3 = "This video is already added";
            break;
        
      }
    }
    tmp$2 = JsxRuntime.jsx("div", {
          children: tmp$3,
          className: css.assetsVideoLinkFormError
        });
  } else {
    tmp$2 = null;
  }
  return JsxRuntime.jsxs(Form.make, {
              className: css.assetsVideoLinkForm,
              onSubmit: (function (param) {
                  dispatch("Submit");
                }),
              children: [
                JsxRuntime.jsx(Label.make, {
                      forId: "provider-form--video-link",
                      children: "Add a YouTube video"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(TextField.make, {
                              id: "provider-form--video-link",
                              value: state.input,
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateInput",
                                        _0: $$event.target.value
                                      });
                                })
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              busy: tmp,
                              submit: true,
                              children: "Add"
                            }),
                        tmp$1
                      ],
                      className: css.assetsVideoLinkFormRow
                    }),
                tmp$2
              ]
            });
}

var make = ProviderEditAssetsTabVideoLinkForm;

export {
  css ,
  parseUrl ,
  initialState ,
  make ,
}
/* css Not a pure module */
