// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  var match = req.field;
  switch (match) {
    case "Hidden" :
        return Json_Encode$JsonCombinators.object([[
                      "hidden",
                      req.value
                    ]]);
    case "SponsoredGlobal" :
        return Json_Encode$JsonCombinators.object([[
                      "sponsored_global",
                      req.value
                    ]]);
    case "SponsoredCountry" :
        return Json_Encode$JsonCombinators.object([[
                      "sponsored_country",
                      req.value
                    ]]);
    case "SponsoredState" :
        return Json_Encode$JsonCombinators.object([[
                      "sponsored_state",
                      req.value
                    ]]);
    case "SponsoredCity" :
        return Json_Encode$JsonCombinators.object([[
                      "sponsored_city",
                      req.value
                    ]]);
    case "ColocationDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "colocation_directory",
                      req.value
                    ]]);
    case "CloudDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "cloud_directory",
                      req.value
                    ]]);
    case "InternetDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "internet_directory",
                      req.value
                    ]]);
    case "BareMetalDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "bare_metal_directory",
                      req.value
                    ]]);
    case "NetworkDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "network_directory",
                      req.value
                    ]]);
    case "ColocationProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "colocation_projects",
                      req.value
                    ]]);
    case "BareMetalProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "bare_metal_projects",
                      req.value
                    ]]);
    case "OverrideCity" :
        return Json_Encode$JsonCombinators.object([[
                      "override_city",
                      Belt_Option.getExn(req.overrideCity)
                    ]]);
    
  }
}

var Req = {
  toJson: toJson
};

function exec(locationSettingId, params) {
  var url = "/dashboard/location_settings/" + ID.toString(locationSettingId);
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: toJson(params)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
