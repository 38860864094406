// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateLocationFeatureRequest from "./requests/CreateLocationFeatureRequest.res.js";
import * as UpdateLocationFeatureRequest from "./requests/UpdateLocationFeatureRequest.res.js";
import * as DestroyLocationFeatureRequest from "./requests/DestroyLocationFeatureRequest.res.js";

var create = CreateLocationFeatureRequest.exec;

var destroy = DestroyLocationFeatureRequest.exec;

var update = UpdateLocationFeatureRequest.exec;

export {
  create ,
  destroy ,
  update ,
}
/* CreateLocationFeatureRequest Not a pure module */
