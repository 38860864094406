// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as FetchApiKeysRequest from "./FetchApiKeysRequest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "provider_id",
                ID.toJson(req.providerId)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(apiKeyId, body) {
  return Rest.$$fetch("/dashboard/api_keys/" + ID.toString(apiKeyId), {
              NAME: "Delete",
              VAL: toJson(body)
            }, {
              NAME: "Json",
              VAL: FetchApiKeysRequest.Res.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
