// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconOk from "../../../../../../../../styleguide/icons/IconOk.res.js";
import * as IconClock from "../../../../../../../../styleguide/icons/IconClock.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditLocationApprovalStatusScss from "./ProviderEditLocationApprovalStatus.scss";

var css = ProviderEditLocationApprovalStatusScss;

function ProviderEditLocationApprovalStatus(props) {
  var isApproved = props.isApproved;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        isApproved ? JsxRuntime.jsx("div", {
                                children: "Approved",
                                className: css.approvedText
                              }) : JsxRuntime.jsx("div", {
                                children: "Draft Saved",
                                className: css.pendingText
                              }),
                        JsxRuntime.jsx("div", {
                              children: isApproved ? Format(props.lastApprovedOn, "MMMM d, yyyy, hh:mm aaaa") : Format(props.changesSaved, "MMMM d, yyyy, hh:mm aaaa"),
                              className: css.approvalDate
                            })
                      ],
                      className: css.approvalTextContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: isApproved ? JsxRuntime.jsx(IconOk.make, {
                              size: "SM",
                              color: "Teal"
                            }) : JsxRuntime.jsx(IconClock.make, {
                              size: "SM",
                              color: "YellowText"
                            }),
                      className: css.approvalIcon
                    })
              ],
              className: css.approvalStatusContainer
            });
}

var make = ProviderEditLocationApprovalStatus;

export {
  css ,
  make ,
}
/* css Not a pure module */
