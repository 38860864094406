// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateApiKeyRequest from "./requests/CreateApiKeyRequest.res.js";
import * as DestroyApiKeyRequest from "./requests/DestroyApiKeyRequest.res.js";

var create = CreateApiKeyRequest.exec;

var destroy = DestroyApiKeyRequest.exec;

export {
  create ,
  destroy ,
}
/* CreateApiKeyRequest Not a pure module */
