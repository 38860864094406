// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H4 from "../../../../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../../../../libs/ID.res.js";
import * as React from "react";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_LocationFeature from "../../../../../../../api/location-features/Api_LocationFeature.res.js";
import * as ProviderEditLocationFeatureForm from "./ProviderEditLocationFeatureForm.res.js";
import * as ProviderEditFormsScss from "../../../common/ProviderEditForms.scss";

var forms = ProviderEditFormsScss;

function reducer(_state, action) {
  return {
          timeout: action._0
        };
}

function ProviderEditLocationFeatures(props) {
  var updateLocation = props.updateLocation;
  var live = props.live;
  var $$location = props.location;
  var match = React.useReducer(reducer, {
        timeout: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var featureCount = $$location.features.length;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H4.make, {
                      className: forms.formTitle,
                      children: "Add Location Features"
                    }),
                JsxRuntime.jsx("div", {
                      children: "Add up to four features of that make this location stand out.",
                      className: forms.note
                    }),
                Belt_Array.mapWithIndex($$location.features, (function (index, feature) {
                        return JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: String(index + 1 | 0) + ".",
                                            className: forms.featureCounter
                                          }),
                                      JsxRuntime.jsx(ProviderEditLocationFeatureForm.make, {
                                            live: live,
                                            feature: feature,
                                            updateLocation: updateLocation,
                                            updateTimeout: (function (timeout) {
                                                var match = state.timeout;
                                                if (match !== undefined) {
                                                  clearTimeout(Belt_Option.getExn(state.timeout));
                                                  return dispatch({
                                                              TAG: "SetTimeOut",
                                                              _0: Caml_option.some(timeout)
                                                            });
                                                } else {
                                                  return dispatch({
                                                              TAG: "SetTimeOut",
                                                              _0: Caml_option.some(timeout)
                                                            });
                                                }
                                              })
                                          })
                                    ],
                                    className: forms.featureWrapper
                                  }, "location-feature--" + String(index) + ID.toString(feature.id));
                      })),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              visuallyDisabled: featureCount >= 4,
                              onClick: (function (param) {
                                  $$Promise.wait(Api_LocationFeature.create({
                                            id: $$location.id
                                          }), (function (x) {
                                          if (x.TAG === "Ok") {
                                            return updateLocation(x._0);
                                          } else {
                                            return SentryLogger.error1({
                                                        rootModule: "ProviderEditLocationFeatures",
                                                        subModulePath: /* [] */0,
                                                        value: "make",
                                                        fullPath: "ProviderEditLocationFeatures.make"
                                                      }, "LocationEditFeatures::Create::Error", [
                                                        "Error",
                                                        x._0
                                                      ]);
                                          }
                                        }));
                                }),
                              children: "Add Feature"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "4 features max",
                              className: forms.note
                            })
                      ],
                      className: forms.featuresButton
                    })
              ],
              className: forms.featuresContainer
            });
}

var make = ProviderEditLocationFeatures;

export {
  forms ,
  reducer ,
  make ,
}
/* forms Not a pure module */
