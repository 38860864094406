// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProjectSettingProvider from "../../../../../../api/project-setting-providers/Api_ProjectSettingProvider.res.js";
import * as ProviderEditFormsScss from "../../common/ProviderEditForms.scss";

var css = ProviderEditFormsScss;

function ProviderProjectSettings(props) {
  var updateProvider = props.updateProvider;
  var live = props.live;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex(props.provider.projectSettingCategories, (function (index, category) {
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(H3.make, {
                                                children: category.title + " Project Services"
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: "Choose from the following services available for this project category. Changes are saved automatically.",
                                                className: css.settingsExplanation
                                              }),
                                          Belt_Array.mapWithIndex(category.subcategories, (function (subIndex, sub) {
                                                  var totalOptions = sub.settings.length;
                                                  var numOfRows = totalOptions / 3 | 0;
                                                  return JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(H4.make, {
                                                                      className: css.directoryTitle,
                                                                      children: sub.title
                                                                    }),
                                                                JsxRuntime.jsx("div", {
                                                                      children: Belt_Array.mapWithIndex(Belt_Array.range(0, numOfRows), (function (index, param) {
                                                                              return JsxRuntime.jsx("div", {
                                                                                          children: Belt_Array.map(Belt_Array.slice(sub.settings, Math.imul(index, 3), 3), (function (setting) {
                                                                                                  var match = React.useState(function () {
                                                                                                        return setting.requested;
                                                                                                      });
                                                                                                  var setRequested = match[1];
                                                                                                  var requested = match[0];
                                                                                                  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                                                              id: "directory-service--" + ID.toString(setting.id),
                                                                                                              size: "SM",
                                                                                                              checked: live === "Live" ? setting.live : requested,
                                                                                                              disabled: live === "Live",
                                                                                                              containerClassName: css.directoryServiceOption,
                                                                                                              onChange: (function (param) {
                                                                                                                  setRequested(function (_prev) {
                                                                                                                        return !requested;
                                                                                                                      });
                                                                                                                  $$Promise.wait(Api_ProjectSettingProvider.update(setting.projectSettingProviderId, {
                                                                                                                            approved: false,
                                                                                                                            live: setting.live,
                                                                                                                            requested: !requested
                                                                                                                          }), (function (x) {
                                                                                                                          if (x.TAG === "Ok") {
                                                                                                                            return updateProvider(x._0);
                                                                                                                          } else {
                                                                                                                            return SentryLogger.error1({
                                                                                                                                        rootModule: "ProviderProjectSettings",
                                                                                                                                        subModulePath: /* [] */0,
                                                                                                                                        value: "make",
                                                                                                                                        fullPath: "ProviderProjectSettings.make"
                                                                                                                                      }, "ProviderEditProjectSettings::Update::Error", [
                                                                                                                                        "Error",
                                                                                                                                        x._0
                                                                                                                                      ]);
                                                                                                                          }
                                                                                                                        }));
                                                                                                                }),
                                                                                                              children: JsxRuntime.jsx("div", {
                                                                                                                    children: setting.title,
                                                                                                                    className: setting.live === requested ? "" : css.serviceChanged
                                                                                                                  })
                                                                                                            }, "directory-service--" + String(subIndex) + "--" + setting.title);
                                                                                                })),
                                                                                          className: css.servicesRow
                                                                                        }, "services-row" + String(index));
                                                                            })),
                                                                      className: css.directoryServices
                                                                    })
                                                              ],
                                                              className: css.directory
                                                            }, "directory--" + String(subIndex));
                                                }))
                                        ],
                                        className: css.projectCategoryContainer
                                      }, "category--" + String(index));
                          })),
                    className: css.directoriesContainer
                  }),
              className: css.container
            });
}

var make = ProviderProjectSettings;

export {
  css ,
  make ,
}
/* css Not a pure module */
