// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditProviderUsers from "./managers/ProviderEditProviderUsers.res.js";
import * as ProviderEditContainersScss from "../common/ProviderEditContainers.scss";

var css = ProviderEditContainersScss;

function ProviderEditPeople(props) {
  var provider = props.provider;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: "People: " + provider.name,
                                    className: css.title
                                  }),
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Managers",
                                      className: Cx.cx([
                                            css.linkContainer,
                                            css.underlinedLink
                                          ])
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Provider.show(provider.slug),
                                            className: css.tabItem,
                                            children: "View Live Provider Page"
                                          }),
                                      className: css.viewLive
                                    })
                              ],
                              className: css.headerTabsContainer
                            })
                      ],
                      className: css.subnavContainer
                    }),
                JsxRuntime.jsx(ProviderEditProviderUsers.make, {
                      provider: provider,
                      providerUsers: props.providerUsers,
                      updateProviderUsers: props.updateProviderUsers
                    })
              ]
            });
}

var make = ProviderEditPeople;

export {
  css ,
  make ,
}
/* css Not a pure module */
