// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as ID from "../../../../../../../libs/ID.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Tooltip from "../../../../../../../styleguide/components/Tooltip/Tooltip.res.js";
import * as Checkbox from "../../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as IconHelpTooltip from "../../../../../../../styleguide/icons/IconHelpTooltip.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_DataCenterCertificationLocation from "../../../../../../../api/data-center-certification-location/Api_DataCenterCertificationLocation.res.js";
import * as ProviderEditFormsScss from "../../../common/ProviderEditForms.scss";

var css = ProviderEditFormsScss;

function ProviderEditLocationCertifications(props) {
  var updateLocation = props.updateLocation;
  var live = props.live;
  var $$location = props.location;
  var totalOptions = $$location.certifications.length;
  var numOfRows = totalOptions / 3 | 0;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H3.make, {
                      children: "Select Certifications"
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(Belt_Array.range(0, numOfRows), (function (index, param) {
                              return JsxRuntime.jsx("div", {
                                          children: Belt_Array.mapWithIndex(Belt_Array.slice($$location.certifications, Math.imul(index, 3), 3), (function (cIndex, certification) {
                                                  var match = React.useState(function () {
                                                        return certification.requested;
                                                      });
                                                  var setRequested = match[1];
                                                  var requested = match[0];
                                                  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                              id: "certification--" + ID.toString(certification.id),
                                                              size: "SM",
                                                              checked: live === "Live" ? certification.live : requested,
                                                              disabled: live === "Live",
                                                              containerClassName: css.directoryServiceOption,
                                                              onChange: (function (param) {
                                                                  setRequested(function (_prev) {
                                                                        return !requested;
                                                                      });
                                                                  $$Promise.wait(Api_DataCenterCertificationLocation.update(certification.certificationLocationId, {
                                                                            approved: false,
                                                                            live: certification.live,
                                                                            requested: !requested
                                                                          }), (function (x) {
                                                                          if (x.TAG === "Ok") {
                                                                            return updateLocation(x._0);
                                                                          } else {
                                                                            return SentryLogger.error1({
                                                                                        rootModule: "ProviderEditLocationCertifications",
                                                                                        subModulePath: /* [] */0,
                                                                                        value: "make",
                                                                                        fullPath: "ProviderEditLocationCertifications.make"
                                                                                      }, "ProviderEditServices::Update::Error", [
                                                                                        "Error",
                                                                                        x._0
                                                                                      ]);
                                                                          }
                                                                        }));
                                                                }),
                                                              children: JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      certification.abbreviation,
                                                                      JsxRuntime.jsx(Tooltip.make, {
                                                                            content: JsxRuntime.jsxs("div", {
                                                                                  children: [
                                                                                    JsxRuntime.jsx("div", {
                                                                                          children: certification.title,
                                                                                          className: css.serviceTitle
                                                                                        }),
                                                                                    JsxRuntime.jsx("div", {
                                                                                          children: certification.summary
                                                                                        })
                                                                                  ],
                                                                                  className: css.serviceTooltip
                                                                                }),
                                                                            position: "Right",
                                                                            tooltipContainerClass: css.tooltip,
                                                                            withoutArrow: true,
                                                                            children: JsxRuntime.jsx(IconHelpTooltip.make, {
                                                                                  size: "SM"
                                                                                })
                                                                          })
                                                                    ],
                                                                    className: Cx.cx([
                                                                          css.serviceLabelContainer,
                                                                          certification.live === requested ? "" : css.serviceChanged
                                                                        ])
                                                                  })
                                                            }, "certification--" + String(cIndex) + "--" + certification.abbreviation);
                                                })),
                                          className: css.servicesRow
                                        }, "services-row" + String(index));
                            })),
                      className: css.certifications
                    })
              ],
              className: css.certificationsContainer
            });
}

var make = ProviderEditLocationCertifications;

export {
  css ,
  make ,
}
/* css Not a pure module */
