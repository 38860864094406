// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateLocationSettingsRequest from "./requests/UpdateLocationSettingsRequest.res.js";
import * as UpdateLocationSettingsHiddenRequest from "./requests/UpdateLocationSettingsHiddenRequest.res.js";

var updateHidden = UpdateLocationSettingsHiddenRequest.exec;

var update = UpdateLocationSettingsRequest.exec;

export {
  updateHidden ,
  update ,
}
/* UpdateLocationSettingsRequest Not a pure module */
