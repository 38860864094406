// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Continents from "../../../../../../models/Continents.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Location from "../../../../../../api/locations/Api_Location.res.js";
import * as Api_Provider from "../../../../../../api/providers/Api_Provider.res.js";
import * as ErrorMessage from "../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as ReactGeoSuggest from "../../../../../../bindings/ReactGeoSuggest.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactGeoSuggestField from "../../../../../../styleguide/forms/ReactGeoSuggestField/ReactGeoSuggestField.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ProviderEditLocationsScss from "../../locations/ProviderEditLocations.scss";

var css = ProviderEditLocationsScss;

var validators_longitude = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.longitude;
      if (x === 0.0) {
        return {
                TAG: "Error",
                _0: "Latitude is required."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.longitude
              };
      }
    })
};

var validators_latitude = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.latitude;
      if (x === 0.0) {
        return {
                TAG: "Error",
                _0: "Latitude is required."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.latitude
              };
      }
    })
};

var validators_country = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.country.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.country
              };
      } else {
        return {
                TAG: "Error",
                _0: "Country is required."
              };
      }
    })
};

var validators_locationState = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.locationState.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.locationState
              };
      } else {
        return {
                TAG: "Error",
                _0: "State is required."
              };
      }
    })
};

var validators_city = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.city.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.city
              };
      } else {
        return {
                TAG: "Error",
                _0: "City is required."
              };
      }
    })
};

var validators_streetAddress1 = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.streetAddress1.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.streetAddress1
              };
      } else {
        return {
                TAG: "Error",
                _0: "Street address is required."
              };
      }
    })
};

var validators_fullAddress = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.fullAddress.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.fullAddress
              };
      } else {
        return {
                TAG: "Error",
                _0: "Address is required."
              };
      }
    })
};

var validators_name = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.name.length;
      if (x !== 0) {
        if (x < 5) {
          return {
                  TAG: "Error",
                  _0: "Name must be longer than 5 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.name
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Name is required."
              };
      }
    })
};

var validators = {
  longitude: validators_longitude,
  latitude: validators_latitude,
  continent: undefined,
  country: validators_country,
  zip: undefined,
  locationState: validators_locationState,
  city: validators_city,
  nearestAirport: undefined,
  streetAddress1: validators_streetAddress1,
  fullAddress: validators_fullAddress,
  name: validators_name
};

function initialFieldsStatuses(_input) {
  return {
          longitude: "Pristine",
          latitude: "Pristine",
          continent: "Pristine",
          country: "Pristine",
          zip: "Pristine",
          locationState: "Pristine",
          city: "Pristine",
          nearestAirport: "Pristine",
          streetAddress1: "Pristine",
          fullAddress: "Pristine",
          name: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.longitude;
  var tmp;
  tmp = typeof match !== "object" ? validators.longitude.validate(input) : match._0;
  var match$1 = fieldsStatuses.latitude;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.latitude.validate(input) : match$1._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.continent
  };
  var match$2 = fieldsStatuses.country;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.country.validate(input) : match$2._0;
  var match_0$1 = {
    TAG: "Ok",
    _0: input.zip
  };
  var match$3 = fieldsStatuses.locationState;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.locationState.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.city;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.city.validate(input) : match$4._0;
  var match_0$2 = {
    TAG: "Ok",
    _0: input.nearestAirport
  };
  var match$5 = fieldsStatuses.streetAddress1;
  var tmp$5;
  tmp$5 = typeof match$5 !== "object" ? validators.streetAddress1.validate(input) : match$5._0;
  var match$6 = fieldsStatuses.fullAddress;
  var tmp$6;
  tmp$6 = typeof match$6 !== "object" ? validators.fullAddress.validate(input) : match$6._0;
  var match$7 = fieldsStatuses.name;
  var tmp$7;
  tmp$7 = typeof match$7 !== "object" ? validators.name.validate(input) : match$7._0;
  var longitudeResult = tmp;
  var longitudeResult$1;
  if (longitudeResult.TAG === "Ok") {
    var latitudeResult = tmp$1;
    if (latitudeResult.TAG === "Ok") {
      var continentResult = match_0;
      if (continentResult.TAG === "Ok") {
        var countryResult = tmp$2;
        if (countryResult.TAG === "Ok") {
          var zipResult = match_0$1;
          if (zipResult.TAG === "Ok") {
            var locationStateResult = tmp$3;
            if (locationStateResult.TAG === "Ok") {
              var cityResult = tmp$4;
              if (cityResult.TAG === "Ok") {
                var nearestAirportResult = match_0$2;
                if (nearestAirportResult.TAG === "Ok") {
                  var streetAddress1Result = tmp$5;
                  if (streetAddress1Result.TAG === "Ok") {
                    var fullAddressResult = tmp$6;
                    if (fullAddressResult.TAG === "Ok") {
                      var nameResult = tmp$7;
                      if (nameResult.TAG === "Ok") {
                        return {
                                TAG: "Valid",
                                output: {
                                  name: nameResult._0,
                                  fullAddress: fullAddressResult._0,
                                  streetAddress1: streetAddress1Result._0,
                                  nearestAirport: nearestAirportResult._0,
                                  city: cityResult._0,
                                  locationState: locationStateResult._0,
                                  zip: zipResult._0,
                                  country: countryResult._0,
                                  continent: continentResult._0,
                                  latitude: latitudeResult._0,
                                  longitude: longitudeResult._0
                                },
                                fieldsStatuses: {
                                  longitude: {
                                    TAG: "Dirty",
                                    _0: longitudeResult,
                                    _1: "Shown"
                                  },
                                  latitude: {
                                    TAG: "Dirty",
                                    _0: latitudeResult,
                                    _1: "Shown"
                                  },
                                  continent: {
                                    TAG: "Dirty",
                                    _0: continentResult,
                                    _1: "Hidden"
                                  },
                                  country: {
                                    TAG: "Dirty",
                                    _0: countryResult,
                                    _1: "Shown"
                                  },
                                  zip: {
                                    TAG: "Dirty",
                                    _0: zipResult,
                                    _1: "Hidden"
                                  },
                                  locationState: {
                                    TAG: "Dirty",
                                    _0: locationStateResult,
                                    _1: "Shown"
                                  },
                                  city: {
                                    TAG: "Dirty",
                                    _0: cityResult,
                                    _1: "Shown"
                                  },
                                  nearestAirport: {
                                    TAG: "Dirty",
                                    _0: nearestAirportResult,
                                    _1: "Hidden"
                                  },
                                  streetAddress1: {
                                    TAG: "Dirty",
                                    _0: streetAddress1Result,
                                    _1: "Shown"
                                  },
                                  fullAddress: {
                                    TAG: "Dirty",
                                    _0: fullAddressResult,
                                    _1: "Shown"
                                  },
                                  name: {
                                    TAG: "Dirty",
                                    _0: nameResult,
                                    _1: "Shown"
                                  }
                                },
                                collectionsStatuses: undefined
                              };
                      }
                      longitudeResult$1 = longitudeResult;
                    } else {
                      longitudeResult$1 = longitudeResult;
                    }
                  } else {
                    longitudeResult$1 = longitudeResult;
                  }
                } else {
                  longitudeResult$1 = longitudeResult;
                }
              } else {
                longitudeResult$1 = longitudeResult;
              }
            } else {
              longitudeResult$1 = longitudeResult;
            }
          } else {
            longitudeResult$1 = longitudeResult;
          }
        } else {
          longitudeResult$1 = longitudeResult;
        }
      } else {
        longitudeResult$1 = longitudeResult;
      }
    } else {
      longitudeResult$1 = longitudeResult;
    }
  } else {
    longitudeResult$1 = longitudeResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            longitude: {
              TAG: "Dirty",
              _0: longitudeResult$1,
              _1: "Shown"
            },
            latitude: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            continent: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            country: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            zip: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            locationState: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            city: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            },
            nearestAirport: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            streetAddress1: {
              TAG: "Dirty",
              _0: tmp$5,
              _1: "Shown"
            },
            fullAddress: {
              TAG: "Dirty",
              _0: tmp$6,
              _1: "Shown"
            },
            name: {
              TAG: "Dirty",
              _0: tmp$7,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurLongitudeField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.longitude, validators_longitude, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: status,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLatitudeField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.latitude, validators_latitude, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: status,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurContinentField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.continent, state.fieldsStatuses.continent, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: status,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurCountryField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.country, validators_country, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: status,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurZipField" :
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.zip, state.fieldsStatuses.zip, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: status,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLocationStateField" :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.locationState, validators_locationState, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: status,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurCityField" :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.city, validators_city, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: status,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNearestAirportField" :
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.nearestAirport, state.fieldsStatuses.nearestAirport, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: status,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurStreetAddress1Field" :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.streetAddress1, validators_streetAddress1, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: status,
                                  fullAddress: init.fullAddress,
                                  name: init.name
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurFullAddressField" :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.fullAddress, validators_fullAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: status,
                                  name: init.name
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNameField" :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  longitude: init.longitude,
                                  latitude: init.latitude,
                                  continent: init.continent,
                                  country: init.country,
                                  zip: init.zip,
                                  locationState: init.locationState,
                                  city: init.city,
                                  nearestAirport: init.nearestAirport,
                                  streetAddress1: init.streetAddress1,
                                  fullAddress: init.fullAddress,
                                  name: status
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateLongitudeField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.longitude, state.submissionStatus, validators_longitude, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: status,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLatitudeField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.latitude, state.submissionStatus, validators_latitude, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: status,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateContinentField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.continent, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: status,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateCountryField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.country, state.submissionStatus, validators_country, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: status,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateZipField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.zip, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: status,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLocationStateField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.locationState, state.submissionStatus, validators_locationState, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: status,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateCityField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.city, state.submissionStatus, validators_city, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: status,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNearestAirportField" :
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.nearestAirport, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: status,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateStreetAddress1Field" :
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.streetAddress1, state.submissionStatus, validators_streetAddress1, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: status,
                                            fullAddress: init.fullAddress,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateFullAddressField" :
                  var nextInput$9 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.fullAddress, state.submissionStatus, validators_fullAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: status,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNameField" :
                  var nextInput$10 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            longitude: init.longitude,
                                            latitude: init.latitude,
                                            continent: init.continent,
                                            country: init.country,
                                            zip: init.zip,
                                            locationState: init.locationState,
                                            city: init.city,
                                            nearestAirport: init.nearestAirport,
                                            streetAddress1: init.streetAddress1,
                                            fullAddress: init.fullAddress,
                                            name: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateLongitude: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLongitudeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLatitude: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLatitudeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateContinent: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateContinentField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateCountry: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateCountryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateZip: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateZipField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLocationState: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLocationStateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateCity: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateCityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateNearestAirport: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNearestAirportField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateStreetAddress1: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateStreetAddress1Field",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateFullAddress: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateFullAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurLongitude: (function () {
              dispatch("BlurLongitudeField");
            }),
          blurLatitude: (function () {
              dispatch("BlurLatitudeField");
            }),
          blurContinent: (function () {
              dispatch("BlurContinentField");
            }),
          blurCountry: (function () {
              dispatch("BlurCountryField");
            }),
          blurZip: (function () {
              dispatch("BlurZipField");
            }),
          blurLocationState: (function () {
              dispatch("BlurLocationStateField");
            }),
          blurCity: (function () {
              dispatch("BlurCityField");
            }),
          blurNearestAirport: (function () {
              dispatch("BlurNearestAirportField");
            }),
          blurStreetAddress1: (function () {
              dispatch("BlurStreetAddress1Field");
            }),
          blurFullAddress: (function () {
              dispatch("BlurFullAddressField");
            }),
          blurName: (function () {
              dispatch("BlurNameField");
            }),
          longitudeResult: Formality.exposeFieldResult(state.fieldsStatuses.longitude),
          latitudeResult: Formality.exposeFieldResult(state.fieldsStatuses.latitude),
          continentResult: Formality.exposeFieldResult(state.fieldsStatuses.continent),
          countryResult: Formality.exposeFieldResult(state.fieldsStatuses.country),
          zipResult: Formality.exposeFieldResult(state.fieldsStatuses.zip),
          locationStateResult: Formality.exposeFieldResult(state.fieldsStatuses.locationState),
          cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
          nearestAirportResult: Formality.exposeFieldResult(state.fieldsStatuses.nearestAirport),
          streetAddress1Result: Formality.exposeFieldResult(state.fieldsStatuses.streetAddress1),
          fullAddressResult: Formality.exposeFieldResult(state.fieldsStatuses.fullAddress),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.longitude;
              if (typeof tmp !== "object") {
                var tmp$1 = match.latitude;
                if (typeof tmp$1 !== "object") {
                  var tmp$2 = match.continent;
                  if (typeof tmp$2 !== "object") {
                    var tmp$3 = match.country;
                    if (typeof tmp$3 !== "object") {
                      var tmp$4 = match.zip;
                      if (typeof tmp$4 !== "object") {
                        var tmp$5 = match.locationState;
                        if (typeof tmp$5 !== "object") {
                          var tmp$6 = match.city;
                          if (typeof tmp$6 !== "object") {
                            var tmp$7 = match.nearestAirport;
                            if (typeof tmp$7 !== "object") {
                              var tmp$8 = match.streetAddress1;
                              if (typeof tmp$8 !== "object") {
                                var tmp$9 = match.fullAddress;
                                if (typeof tmp$9 !== "object") {
                                  var tmp$10 = match.name;
                                  if (typeof tmp$10 !== "object") {
                                    return false;
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              return true;
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var LocationForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditLocationNew(props) {
  var updateLocation = props.updateLocation;
  var providerId = props.providerId;
  var initialInput = React.useMemo((function () {
          return {
                  name: "",
                  fullAddress: "",
                  streetAddress1: "",
                  nearestAirport: "",
                  city: "",
                  locationState: "",
                  zip: "",
                  country: "",
                  continent: "",
                  latitude: 0.0,
                  longitude: 0.0
                };
        }), []);
  var match = Hooks.useReducer({
        nameValid: true
      }, (function (_state, action) {
          if (action.TAG === "SetNameValid") {
            return {
                    TAG: "Update",
                    _0: {
                      nameValid: action._0
                    }
                  };
          }
          var id = action._1;
          var name = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (param) {
                      var dispatch = param.dispatch;
                      $$Promise.wait(Api_Provider.validateLocationName(undefined, id, name), (function (x) {
                              if (x.TAG === "Ok") {
                                return dispatch({
                                            TAG: "SetNameValid",
                                            _0: x._0.valid
                                          });
                              }
                              SentryLogger.error1({
                                    rootModule: "ProviderEditLocationNew",
                                    subModulePath: /* [] */0,
                                    value: "make",
                                    fullPath: "ProviderEditLocationNew.make"
                                  }, "CreateNewLocation::Error", [
                                    "Error",
                                    x._0
                                  ]);
                              dispatch({
                                    TAG: "SetNameValid",
                                    _0: false
                                  });
                            }));
                    })
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_Location.create(providerId, {
                    name: output.name,
                    fullAddress: output.fullAddress,
                    streetAddress1: output.streetAddress1,
                    city: output.city,
                    state: output.locationState,
                    zip: output.zip,
                    country: output.country,
                    continent: output.continent,
                    latitude: output.latitude,
                    longitude: output.longitude
                  }), (function (x) {
                  if (x.TAG === "Ok") {
                    return updateLocation(x._0);
                  } else {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditLocationNew",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditLocationNew.make"
                              }, "CreateNewLocation::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match$1 = form.input.name.length;
  var match$2 = state.nameValid;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: "Add New Location",
                            className: css.newLocationText
                          }),
                      JsxRuntime.jsxs("form", {
                            children: [
                              JsxRuntime.jsx(Label.make, {
                                    forId: "location-name",
                                    children: "Location Name"
                                  }),
                              JsxRuntime.jsx(TextField.make, {
                                    id: "location-name",
                                    value: form.input.name,
                                    placeholder: "Name of location",
                                    disabled: form.submitting,
                                    wrapperClassName: css.textField,
                                    onChange: (function ($$event) {
                                        var value = $$event.target.value;
                                        form.updateName((function (input, value) {
                                                return {
                                                        name: value,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), value);
                                        dispatch({
                                              TAG: "ValidateName",
                                              _0: value,
                                              _1: providerId
                                            });
                                      })
                                  }),
                              fieldError(form.nameResult),
                              match$1 !== 0 && !match$2 ? JsxRuntime.jsx(ErrorMessage.make, {
                                      className: css.error,
                                      children: "Name is already taken"
                                    }) : null,
                              JsxRuntime.jsx(Label.make, {
                                    forId: "locations--address",
                                    children: "Address"
                                  }),
                              JsxRuntime.jsx(ReactGeoSuggestField.make, {
                                    id: "location-address",
                                    value: form.input.fullAddress,
                                    radius: "20",
                                    placeholder: "Type and select an address.",
                                    disabled: form.submitting,
                                    location: new (google.maps.LatLng)(53.558572, 9.9278215),
                                    onSuggestSelect: (function (suggest) {
                                        var result = ReactGeoSuggest.ReactGeoSuggestData.fromJson(suggest);
                                        form.updateFullAddress((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: value,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), Belt_Option.getExn(result.description));
                                        form.updateLatitude((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: value,
                                                        longitude: input.longitude
                                                      };
                                              }), result.location.lat);
                                        form.updateLongitude((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: value
                                                      };
                                              }), result.location.lng);
                                        var gmaps = result.gmaps;
                                        if (gmaps === undefined) {
                                          return ;
                                        }
                                        console.log(gmaps);
                                        var addresses = gmaps.address_components;
                                        var streetNumber = {
                                          contents: ""
                                        };
                                        var route = {
                                          contents: ""
                                        };
                                        var cityOrTown = {
                                          contents: ""
                                        };
                                        var stateOrProvince = {
                                          contents: ""
                                        };
                                        var country = {
                                          contents: ""
                                        };
                                        var zip = {
                                          contents: ""
                                        };
                                        var countryCode = {
                                          contents: ""
                                        };
                                        Belt_Array.forEach(addresses, (function (address) {
                                                var addressType = Belt_Array.get(address.types, 0);
                                                if (addressType !== undefined) {
                                                  if (addressType === "street_number") {
                                                    streetNumber.contents = address.long_name;
                                                  }
                                                  if (addressType === "route") {
                                                    route.contents = address.long_name;
                                                  }
                                                  if (addressType === "locality" || addressType === "postal_town") {
                                                    cityOrTown.contents = address.long_name;
                                                  }
                                                  if (addressType === "administrative_area_level_1") {
                                                    stateOrProvince.contents = address.long_name;
                                                    if (cityOrTown.contents === "") {
                                                      cityOrTown.contents = address.long_name;
                                                    }
                                                    
                                                  }
                                                  if (addressType === "country") {
                                                    country.contents = address.long_name;
                                                    countryCode.contents = address.short_name;
                                                    if (stateOrProvince.contents === "") {
                                                      stateOrProvince.contents = address.long_name;
                                                    }
                                                    
                                                  }
                                                  if (addressType === "postal_code") {
                                                    zip.contents = address.long_name;
                                                    return ;
                                                  } else {
                                                    return ;
                                                  }
                                                }
                                                
                                              }));
                                        form.updateStreetAddress1((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: value,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), streetNumber.contents + " " + route.contents);
                                        form.updateCity((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: value,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), cityOrTown.contents);
                                        form.updateLocationState((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: value,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), stateOrProvince.contents);
                                        form.updateCountry((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: value,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), country.contents);
                                        form.updateZip((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: value,
                                                        country: input.country,
                                                        continent: input.continent,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), zip.contents);
                                        form.updateContinent((function (input, value) {
                                                return {
                                                        name: input.name,
                                                        fullAddress: input.fullAddress,
                                                        streetAddress1: input.streetAddress1,
                                                        nearestAirport: input.nearestAirport,
                                                        city: input.city,
                                                        locationState: input.locationState,
                                                        zip: input.zip,
                                                        country: input.country,
                                                        continent: value,
                                                        latitude: input.latitude,
                                                        longitude: input.longitude
                                                      };
                                              }), Continents.toString(Continents.fromCountryCode(countryCode.contents)));
                                      })
                                  }),
                              fieldError(form.fullAddressResult),
                              JsxRuntime.jsx(Label.make, {
                                    forId: "location-streetAddress1",
                                    children: "Street Address"
                                  }),
                              JsxRuntime.jsx(TextField.make, {
                                    id: "location-streetAddress1",
                                    value: form.input.streetAddress1,
                                    disabled: true,
                                    wrapperClassName: css.textField
                                  }),
                              fieldError(form.streetAddress1Result),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-city",
                                                    children: "City"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-city",
                                                    value: form.input.city,
                                                    disabled: true,
                                                    wrapperClassName: css.textField
                                                  }),
                                              fieldError(form.cityResult)
                                            ],
                                            className: css.subField
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-locationState",
                                                    children: "State"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-locationState",
                                                    value: form.input.locationState,
                                                    disabled: true,
                                                    wrapperClassName: css.textField
                                                  }),
                                              fieldError(form.locationStateResult)
                                            ],
                                            className: css.subField
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-zip",
                                                    children: "Postal Code"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-zip",
                                                    value: form.input.zip,
                                                    disabled: true,
                                                    wrapperClassName: css.textField
                                                  }),
                                              fieldError(form.zipResult)
                                            ],
                                            className: css.subField
                                          })
                                    ],
                                    className: css.locationFieldGroup
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-country",
                                                    children: "Country"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-country",
                                                    value: form.input.country,
                                                    disabled: true,
                                                    wrapperClassName: css.textField
                                                  }),
                                              fieldError(form.countryResult)
                                            ],
                                            className: css.subField
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "location-continent",
                                                    children: "Continent"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "location-continent",
                                                    value: form.input.continent,
                                                    disabled: true,
                                                    wrapperClassName: css.textField
                                                  }),
                                              fieldError(form.continentResult)
                                            ],
                                            className: css.subField
                                          })
                                    ],
                                    className: css.locationFieldGroup
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Button.make, {
                                            size: "SM",
                                            color: "Gray",
                                            onClick: props.closeNewLocation,
                                            children: "Cancel"
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: "SM",
                                            color: "Pink",
                                            disabled: state.nameValid ? form.submitting : true,
                                            visuallyDisabled: !state.nameValid,
                                            busy: form.submitting,
                                            submit: true,
                                            children: "Add Location"
                                          })
                                    ],
                                    className: css.newLocationButtons
                                  })
                            ],
                            className: css.newLocationForm,
                            onSubmit: (function ($$event) {
                                $$event.preventDefault();
                                form.submit();
                              })
                          })
                    ],
                    className: css.newLocationDialog
                  }),
              className: css.newLocationOverlay,
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                })
            });
}

var make = ProviderEditLocationNew;

export {
  css ,
  LocationForm ,
  make ,
}
/* css Not a pure module */
