// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Toggle from "../../../../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as ProviderSetting from "../../../../../../../../models/ProviderSetting.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderSetting from "../../../../../../../../api/provider-settings/Api_ProviderSetting.res.js";
import * as ProviderEditAgreementButtonScss from "./ProviderEditAgreementButton.scss";

var css = ProviderEditAgreementButtonScss;

function ProviderEditAgreementButton(props) {
  var updateProvider = props.updateProvider;
  var settings = props.settings;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: ProviderSetting.Agreement.toString(ProviderSetting.Agreement.fromBool(settings.agreement)),
                            className: Cx.cx([css.inactiveText])
                          }),
                      JsxRuntime.jsx(Toggle.make, {
                            on: settings.agreement,
                            size: "SM",
                            onChange: (function () {
                                var agreement = settings.agreement ? "NoAgreement" : "Agreement";
                                $$Promise.wait(Api_ProviderSetting.updateAgreement(settings.id, agreement), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return updateProvider(x._0);
                                        } else {
                                          return SentryLogger.error1({
                                                      rootModule: "ProviderEditAgreementButton",
                                                      subModulePath: /* [] */0,
                                                      value: "make",
                                                      fullPath: "ProviderEditAgreementButton.make"
                                                    }, "UpdateAgreement::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                        }
                                      }));
                              })
                          })
                    ],
                    className: Cx.cx([css.toggleContainer])
                  }),
              className: css.actionButtonsContainer
            });
}

var make = ProviderEditAgreementButton;

export {
  css ,
  make ,
}
/* css Not a pure module */
