// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProviderEditTab from "../../ProviderEditTab.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderAllSettings from "./all/ProviderAllSettings.res.js";
import * as ProviderProjectSettings from "./projects/ProviderProjectSettings.res.js";
import * as ProviderEditContainersScss from "../common/ProviderEditContainers.scss";

var css = ProviderEditContainersScss;

function ProviderEditSettings(props) {
  var updateProvider = props.updateProvider;
  var userRole = props.userRole;
  var provider = props.provider;
  var initialState = React.useMemo((function () {
          var tmp;
          tmp = userRole === "Admin" ? "All" : "ProjectSettings";
          return {
                  selectedOption: tmp
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    selectedOption: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.selectedOption;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: "Settings: " + provider.name,
                                    className: css.title
                                  }),
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                Belt_Array.mapWithIndex(ProviderEditTab.SettingsNav.Tab.tabs, (function (index, tab) {
                                        var match = tab.tab;
                                        if (match === "All") {
                                          if (userRole === "Admin") {
                                            return JsxRuntime.jsx("div", {
                                                        children: tab.title,
                                                        className: Cx.cx([
                                                              css.linkContainer,
                                                              state.selectedOption === tab.tab ? css.underlinedLink : ""
                                                            ]),
                                                        onClick: (function (param) {
                                                            dispatch({
                                                                  TAG: "ToggleOptions",
                                                                  _0: tab.tab
                                                                });
                                                          })
                                                      }, String(index));
                                          } else {
                                            return null;
                                          }
                                        } else {
                                          return JsxRuntime.jsx("div", {
                                                      children: tab.title,
                                                      className: Cx.cx([
                                                            css.linkContainer,
                                                            state.selectedOption === tab.tab ? css.underlinedLink : ""
                                                          ]),
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "ToggleOptions",
                                                                _0: tab.tab
                                                              });
                                                        })
                                                    }, String(index));
                                        }
                                      })),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Provider.show(provider.slug),
                                            className: css.tabItem,
                                            children: "View Live Provider Page"
                                          }),
                                      className: css.viewLive
                                    })
                              ],
                              className: css.headerTabsContainer
                            })
                      ],
                      className: css.subnavContainer
                    }),
                match$1 === "All" ? JsxRuntime.jsx(ProviderAllSettings.make, {
                        provider: provider,
                        updateProvider: updateProvider
                      }) : JsxRuntime.jsx(ProviderProjectSettings.make, {
                        provider: provider,
                        live: props.live,
                        updateProvider: updateProvider
                      })
              ]
            });
}

var make = ProviderEditSettings;

export {
  css ,
  make ,
}
/* css Not a pure module */
