// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as FetchProviderUsersRequest from "./FetchProviderUsersRequest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "page",
                req.page
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(providerUserId, body) {
  return Rest.$$fetch("/dashboard/provider_users/" + ID.toString(providerUserId) + "/resend", {
              NAME: "Patch",
              VAL: toJson(body)
            }, {
              NAME: "Json",
              VAL: FetchProviderUsersRequest.Res.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
