// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconOk from "../../../../../styleguide/icons/IconOk.res.js";
import * as IconEdit from "../../../../../styleguide/icons/IconEdit.res.js";
import * as IconInfo from "../../../../../styleguide/icons/IconInfo.res.js";
import * as IconClock from "../../../../../styleguide/icons/IconClock.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditApprovalStatusScss from "./ProviderEditApprovalStatus.scss";

var css = ProviderEditApprovalStatusScss;

function ProviderEditApprovalStatus(props) {
  var isApproved = props.isApproved;
  var tmp;
  switch (props.editStatus) {
    case "Editing" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        isApproved ? JsxRuntime.jsx("div", {
                                children: "Approved",
                                className: css.approvedText
                              }) : JsxRuntime.jsx("div", {
                                children: "Draft Saved",
                                className: css.pendingText
                              }),
                        JsxRuntime.jsx("div", {
                              children: isApproved ? Format(props.lastApprovedOn, "MMMM d, yyyy, hh:mm aaaa") : Format(props.changesSaved, "MMMM d, yyyy, hh:mm aaaa"),
                              className: css.approvalDate
                            })
                      ],
                      className: css.approvalTextContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: isApproved ? JsxRuntime.jsx(IconOk.make, {
                              size: "SM",
                              color: "Teal"
                            }) : JsxRuntime.jsx(IconClock.make, {
                              size: "SM",
                              color: "YellowText"
                            }),
                      className: css.approvalIcon
                    })
              ]
            });
        break;
    case "Updating" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: "Updating",
                            className: css.pendingText
                          }),
                      className: css.approvalTextContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconEdit.make, {
                            size: "SM",
                            color: "YellowText"
                          }),
                      className: css.approvalIcon
                    })
              ]
            });
        break;
    case "Error" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Error",
                              className: css.errorText
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Please refresh the page.",
                              className: css.approvalDate
                            })
                      ],
                      className: css.approvalTextContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconInfo.make, {
                            size: "SM",
                            color: "Red"
                          }),
                      className: css.approvalIcon
                    })
              ]
            });
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: css.approvalStatusContainer
            });
}

var make = ProviderEditApprovalStatus;

export {
  css ,
  make ,
}
/* css Not a pure module */
