// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  var match = req.field;
  switch (match) {
    case "Agreement" :
        return Json_Encode$JsonCombinators.object([[
                      "agreement",
                      req.value
                    ]]);
    case "BareMetalDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "bare_metal_directory",
                      req.value
                    ]]);
    case "BareMetalProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "bare_metal_projects",
                      req.value
                    ]]);
    case "CloudDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "cloud_directory",
                      req.value
                    ]]);
    case "CloudIaasProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "cloud_iaas_projects",
                      req.value
                    ]]);
    case "CloudServersProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "cloud_servers_projects",
                      req.value
                    ]]);
    case "ColocationDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "colocation_directory",
                      req.value
                    ]]);
    case "ColocationMarketplace" :
        return Json_Encode$JsonCombinators.object([[
                      "colocation_marketplace",
                      req.value
                    ]]);
    case "ColocationProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "colocation_projects",
                      req.value
                    ]]);
    case "InternetDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "internet_directory",
                      req.value
                    ]]);
    case "InternetProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "internet_projects",
                      req.value
                    ]]);
    case "NetworkDirectory" :
        return Json_Encode$JsonCombinators.object([[
                      "network_directory",
                      req.value
                    ]]);
    case "NetworkMplsProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "network_mpls_projects",
                      req.value
                    ]]);
    case "NetworkPrivateLineProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "network_private_line_projects",
                      req.value
                    ]]);
    case "NetworkSdwanProjects" :
        return Json_Encode$JsonCombinators.object([[
                      "network_sdwan_projects",
                      req.value
                    ]]);
    case "ResourceDownloadLeads" :
        return Json_Encode$JsonCombinators.object([[
                      "resource_download_leads",
                      req.value
                    ]]);
    
  }
}

var Req = {
  toJson: toJson
};

function exec(providerSettingId, params) {
  var url = "/dashboard/provider_settings/" + ID.toString(providerSettingId);
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: toJson(params)
            }, {
              NAME: "Json",
              VAL: Provider.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
