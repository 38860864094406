// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Toggle from "../../../../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as $$Location from "../../../../../../../../models/Location.res.js";
import * as Api_Location from "../../../../../../../../api/locations/Api_Location.res.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditLocationActiveButtonScss from "./ProviderEditLocationActiveButton.scss";

var css = ProviderEditLocationActiveButtonScss;

function reducer(_state, action) {
  var $$location = action._0;
  return {
          active: $$Location.Active.fromBool($$location.active),
          location: $$location
        };
}

function ProviderEditLocationActiveButton(props) {
  var $$location = props.location;
  var initialState_active = props.active;
  var initialState = {
    active: initialState_active,
    location: $$location
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.active;
  var tmp;
  tmp = match$1 === "Active" ? true : false;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: $$Location.Active.toString(state.active),
                            className: Cx.cx([css.inactiveText])
                          }),
                      JsxRuntime.jsx(Toggle.make, {
                            on: tmp,
                            size: "SM",
                            onChange: (function () {
                                var match = state.active;
                                var tmp;
                                tmp = match === "Active" ? "Inactive" : "Active";
                                if (tmp === "Active") {
                                  return $$Promise.wait(Api_Location.activate($$location.id), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "UpdateLocation",
                                                              _0: x._0
                                                            });
                                                } else {
                                                  return SentryLogger.error1({
                                                              rootModule: "ProviderEditLocationActiveButton",
                                                              subModulePath: /* [] */0,
                                                              value: "make",
                                                              fullPath: "ProviderEditLocationActiveButton.make"
                                                            }, "ProviderEditLocationActiveButton" + "::Activate::Error", [
                                                              "Error",
                                                              x._0
                                                            ]);
                                                }
                                              }));
                                } else {
                                  return $$Promise.wait(Api_Location.deactivate($$location.id), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "UpdateLocation",
                                                              _0: x._0
                                                            });
                                                } else {
                                                  return SentryLogger.error1({
                                                              rootModule: "ProviderEditLocationActiveButton",
                                                              subModulePath: /* [] */0,
                                                              value: "make",
                                                              fullPath: "ProviderEditLocationActiveButton.make"
                                                            }, "ProviderEditLocationActiveButton" + "::Deactivate::Error", [
                                                              "Error",
                                                              x._0
                                                            ]);
                                                }
                                              }));
                                }
                              })
                          })
                    ],
                    className: Cx.cx([css.toggleContainer])
                  }),
              className: css.actionButtonsContainer
            });
}

var make = ProviderEditLocationActiveButton;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
