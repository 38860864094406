// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Select from "../../../../../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderSetting from "../../../../../../../../api/provider-settings/Api_ProviderSetting.res.js";

var masters = [
  "Not Available",
  "AppDirect",
  "Avant",
  "Direct",
  "Intelisys",
  "TCG",
  "WTG",
  "Bridgepointe"
];

function ProviderEditMasterDropdown(props) {
  var updateProvider = props.updateProvider;
  var settings = props.settings;
  var match = React.useState(function () {
        return settings.master;
      });
  var setMaster = match[1];
  return JsxRuntime.jsxs(Select.make, {
              id: "masterSetting",
              value: match[0],
              iconSize: "XXS",
              iconColor: "GrayText",
              onChange: (function ($$event) {
                  var value = $$event.target.value;
                  setMaster(function (_prev) {
                        return value;
                      });
                  $$Promise.wait(Api_ProviderSetting.updateMaster(settings.id, value), (function (x) {
                          if (x.TAG === "Ok") {
                            return updateProvider(x._0);
                          } else {
                            return SentryLogger.error1({
                                        rootModule: "ProviderEditMasterDropdown",
                                        subModulePath: /* [] */0,
                                        value: "make",
                                        fullPath: "ProviderEditMasterDropdown.make"
                                      }, "UpdateAgreement::Error", [
                                        "Error",
                                        x._0
                                      ]);
                          }
                        }));
                }),
              children: [
                JsxRuntime.jsx(Select.DefaultOption.make, {
                      children: "Not Available"
                    }),
                Belt_Array.map(masters, (function (master) {
                        return JsxRuntime.jsx(Select.$$Option.make, {
                                    value: master,
                                    children: master
                                  }, master);
                      }))
              ]
            });
}

var make = ProviderEditMasterDropdown;

export {
  masters ,
  make ,
}
/* react Not a pure module */
