// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Toggle from "../../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Location from "../../../../../../models/Location.res.js";
import * as Provider from "../../../../../../models/Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditLocationActiveButton from "./components/active-button/ProviderEditLocationActiveButton.res.js";
import * as ProviderEditLocationApprovalStatus from "./components/approval-status/ProviderEditLocationApprovalStatus.res.js";
import * as ProviderEditLocationApproveChanges from "./components/approval-button/ProviderEditLocationApproveChanges.res.js";
import * as ProviderEditLocationStickyBarScss from "./ProviderEditLocationStickyBar.scss";

var css = ProviderEditLocationStickyBarScss;

function ProviderEditLocationStickyBar(props) {
  var userRole = props.userRole;
  var live = props.live;
  var $$location = props.location;
  var tmp;
  tmp = userRole === "Admin" ? $$location.isApproved : true;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(ProviderEditLocationActiveButton.make, {
                            active: $$Location.Active.fromBool($$location.active),
                            location: $$location
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("p", {
                                          children: Provider.Edit.Live.viewToString(live),
                                          className: Cx.cx([css.inactiveText])
                                        }),
                                    JsxRuntime.jsx(Toggle.make, {
                                          on: live === "Live",
                                          size: "SM",
                                          onChange: props.toggleLive
                                        })
                                  ],
                                  className: Cx.cx([css.toggleContainer])
                                }),
                            className: css.actionButtonsContainer
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(ProviderEditLocationApprovalStatus.make, {
                                    isApproved: $$location.isApproved,
                                    lastApprovedOn: $$location.lastApprovedOn,
                                    changesSaved: $$location.currentDraft.updatedAt
                                  }),
                              JsxRuntime.jsx(ProviderEditLocationApproveChanges.make, {
                                    userRole: userRole,
                                    approved: $$location.isApproved,
                                    approve: props.approve,
                                    disabled: tmp
                                  })
                            ],
                            className: css.approvalContainer
                          })
                    ],
                    className: css.innerContainer
                  }),
              className: css.container,
              id: "provider-sticky-bar"
            });
}

var make = ProviderEditLocationStickyBar;

export {
  css ,
  make ,
}
/* css Not a pure module */
