import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../bundles/Client/common/actions/userActions';
import { toggleCart, setCartQty } from '../../../bundles/Client/common/actions/cartActions';

import ProviderEdit from './ProviderEdit.res.js';

const mapStateToProps = state => ({
  reduxProps: {
    isUserLoggedIn: !!state.user.id,
  },
});

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
    toggleCart: () => dispatch(toggleCart()),
    setCartQty: qty => dispatch(setCartQty(qty)),
  },
});

const ProviderEditContainer = connect(mapStateToProps, mapDispatchToProps)(ProviderEdit);

export default props => {
  const { context, ...filteredProps } = props;
  return (
    <ReduxProvider {...props}>
      <ProviderEditContainer props={filteredProps} context={context} />
    </ReduxProvider>
  );
};
