// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as IconClose from "../../../../../../styleguide/icons/IconClose.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditManagerForm from "./components/ProviderEditManagerForm.res.js";
import * as ProviderEditManagerScss from "./ProviderEditManager.scss";

var css = ProviderEditManagerScss;

function ProviderEditManager(props) {
  var close = props.close;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: "Edit Manager"
                                  }),
                              JsxRuntime.jsx("h3", {
                                    children: "Select a new role for this manager or remove from the account."
                                  }),
                              JsxRuntime.jsx(ProviderEditManagerForm.make, {
                                    providerUser: props.providerUser,
                                    providerUsers: props.providerUsers,
                                    remove: props.remove,
                                    close: close,
                                    updateProviderUsers: props.updateProviderUsers
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: css.sideModal,
                      id: "sideModal"
                    })
              ],
              className: css.sideModalContainer,
              id: "inviteModal"
            });
}

var make = ProviderEditManager;

export {
  css ,
  make ,
}
/* css Not a pure module */
