// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../styleguide/components/Heading/H3.res.js";
import * as Form from "../../../../../styleguide/forms/Form/Form.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../styleguide/icons/IconMenu.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Api_ApiKey from "../../../../../api/api-keys/Api_ApiKey.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Provider from "../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import * as ProviderEditFormsScss from "../common/ProviderEditForms.scss";
import * as ProviderEditContainersScss from "../common/ProviderEditContainers.scss";

var css = ProviderEditContainersScss;

var forms = ProviderEditFormsScss;

var validators = {
  projectWebhookUrl: undefined
};

function initialFieldsStatuses(_input) {
  return {
          projectWebhookUrl: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            projectWebhookUrl: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, param, param$1) {
  var match = {
    TAG: "Ok",
    _0: input.projectWebhookUrl
  };
  if (match.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              projectWebhookUrl: match._0
            },
            fieldsStatuses: {
              projectWebhookUrl: {
                TAG: "Dirty",
                _0: match,
                _1: "Hidden"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              projectWebhookUrl: {
                TAG: "Dirty",
                _0: match,
                _1: "Hidden"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurProjectWebhookUrlField" :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.projectWebhookUrl, state.fieldsStatuses.projectWebhookUrl, (function (status) {
                          return {
                                  projectWebhookUrl: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateProjectWebhookUrlField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.projectWebhookUrl, (function (status) {
                                    return {
                                            projectWebhookUrl: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                projectWebhookUrl: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                projectWebhookUrl: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateProjectWebhookUrl: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProjectWebhookUrlField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurProjectWebhookUrl: (function () {
              dispatch("BlurProjectWebhookUrlField");
            }),
          projectWebhookUrlResult: Formality.exposeFieldResult(state.fieldsStatuses.projectWebhookUrl),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.projectWebhookUrl;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ApiKeyForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditApi(props) {
  var updateApiKeys = props.updateApiKeys;
  var updateProvider = props.updateProvider;
  var live = props.live;
  var provider = props.provider;
  var initialInput = React.useMemo((function () {
          return {
                  projectWebhookUrl: provider.currentDraft.projectWebhookUrl
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          var init = provider.currentDraft;
          $$Promise.wait(Api_Provider.updateDraft(provider.currentDraft.id, {
                    id: init.id,
                    name: init.name,
                    companyType: init.companyType,
                    tickerSymbol: init.tickerSymbol,
                    numberEmployees: init.numberEmployees,
                    yearFounded: init.yearFounded,
                    hqFullAddress: init.hqFullAddress,
                    displayDialCode: init.displayDialCode,
                    displayPhoneNumber: init.displayPhoneNumber,
                    supportPhoneNumber: init.supportPhoneNumber,
                    websiteUrl: init.websiteUrl,
                    description: init.description,
                    logo: init.logo,
                    projectWebhookUrl: output.projectWebhookUrl,
                    updatedAt: init.updatedAt,
                    metaTitle: init.metaTitle,
                    metaDescription: init.metaDescription
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditApi",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditApi.make"
                              }, "UpdateLocationDraft::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  var res = x._0;
                  updateProvider(res);
                  cb.notifyOnSuccess({
                        projectWebhookUrl: res.currentDraft.projectWebhookUrl
                      });
                }));
        }));
  var match = Hooks.useReducer({
        openDestroyConfirm: false,
        currentApiKey: undefined,
        timeout: undefined
      }, (function (state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "Update",
                    _0: {
                      openDestroyConfirm: false,
                      currentApiKey: undefined,
                      timeout: state.timeout
                    }
                  };
          } else if (action.TAG === "OpenDestroyConfirm") {
            return {
                    TAG: "Update",
                    _0: {
                      openDestroyConfirm: true,
                      currentApiKey: Caml_option.some(action._0),
                      timeout: state.timeout
                    }
                  };
          } else {
            return {
                    TAG: "Update",
                    _0: {
                      openDestroyConfirm: state.openDestroyConfirm,
                      currentApiKey: state.currentApiKey,
                      timeout: action._0
                    }
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var createApiKey = function (param) {
    $$Promise.wait(Api_ApiKey.create({
              providerId: provider.id
            }), (function (x) {
            if (x.TAG === "Ok") {
              return updateApiKeys(x._0.apiKeys);
            } else {
              return SentryLogger.error1({
                          rootModule: "ProviderEditApi",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProviderEditApi.make"
                        }, "CreateApiKey::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: "API Manager: " + provider.name,
                                    className: css.title
                                  }),
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "API",
                                      className: Cx.cx([
                                            css.linkContainer,
                                            css.underlinedLink
                                          ])
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Provider.show(provider.slug),
                                            className: css.tabItem,
                                            children: "View Live Provider Page"
                                          }),
                                      className: css.viewLive
                                    })
                              ],
                              className: css.headerTabsContainer
                            })
                      ],
                      className: css.subnavContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H3.make, {
                                    children: "Project Webhook URL"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Form.make, {
                                          className: forms.editWebhookUrl,
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Label.make, {
                                                        forId: "edit-provider-form--name",
                                                        children: "URL"
                                                      }),
                                                  JsxRuntime.jsx(TextField.make, {
                                                        id: "edit-provider-form--name",
                                                        value: live === "Live" ? provider.projectWebhookUrl : form.input.projectWebhookUrl,
                                                        placeholder: "https://www.example.com/endpoint",
                                                        disabled: live === "Live" || form.submitting ? true : false,
                                                        className: provider.projectWebhookUrl === form.input.projectWebhookUrl ? "" : forms.fieldChanged,
                                                        onChange: (function ($$event) {
                                                            form.updateProjectWebhookUrl((function (_input, value) {
                                                                    return {
                                                                            projectWebhookUrl: value
                                                                          };
                                                                  }), $$event.target.value);
                                                            var match = state.timeout;
                                                            if (match !== undefined) {
                                                              clearTimeout(Belt_Option.getExn(state.timeout));
                                                              return dispatch({
                                                                          TAG: "SetTimeout",
                                                                          _0: Caml_option.some(setTimeout((function () {
                                                                                      form.submit();
                                                                                    }), 1200))
                                                                        });
                                                            } else {
                                                              return dispatch({
                                                                          TAG: "SetTimeout",
                                                                          _0: Caml_option.some(setTimeout((function () {
                                                                                      form.submit();
                                                                                    }), 1200))
                                                                        });
                                                            }
                                                          })
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          "This is the URL where we will push data and information related to projects. Changes are automatically saved.",
                                                          JsxRuntime.jsx("br", {})
                                                        ],
                                                        className: forms.subText
                                                      })
                                                ],
                                                className: forms.fieldRow
                                              })
                                        }),
                                    className: forms.formContainer
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(H3.make, {
                                            children: "API Keys"
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: "XS",
                                            color: "Primary",
                                            onClick: createApiKey,
                                            children: "Add New Key"
                                          })
                                    ],
                                    className: forms.apiHeader
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: Belt_Array.mapWithIndex(props.apiKeys, (function (index, apiKey) {
                                            return JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsxs("div", {
                                                                children: [
                                                                  JsxRuntime.jsx("div", {
                                                                        children: "Key #" + String(index + 1 | 0),
                                                                        className: forms.apiNumber
                                                                      }),
                                                                  JsxRuntime.jsx("div", {
                                                                        children: (function (__x) {
                                                                              return Js_string.slice(0, 80, __x);
                                                                            })(apiKey.key) + "... "
                                                                      }),
                                                                  JsxRuntime.jsxs("div", {
                                                                        children: [
                                                                          "Created: " + Format(apiKey.createdAt, "MM/dd/yyyy"),
                                                                          " • ",
                                                                          "Expires: " + Format(apiKey.expiresAt, "MM/dd/yyyy")
                                                                        ],
                                                                        className: forms.apiInfo
                                                                      })
                                                                ],
                                                                className: forms.apiSummary
                                                              }),
                                                          JsxRuntime.jsx("div", {
                                                                children: JsxRuntime.jsxs(Popover.make, {
                                                                      children: [
                                                                        JsxRuntime.jsx(Popover.Trigger.make, {
                                                                              children: JsxRuntime.jsx(Control.make, {
                                                                                    children: JsxRuntime.jsx(IconMenu.make, {
                                                                                          size: "MD",
                                                                                          color: "Teal"
                                                                                        })
                                                                                  })
                                                                            }),
                                                                        JsxRuntime.jsxs(Dropdown.Body.make, {
                                                                              position: {
                                                                                TAG: "Below",
                                                                                _0: "RightEdge"
                                                                              },
                                                                              className: forms.dropdownBody,
                                                                              children: [
                                                                                JsxRuntime.jsx(Control.make, {
                                                                                      className: forms.dropdownBodyRow,
                                                                                      children: JsxRuntime.jsx(ReactCopyToClipboard.CopyToClipboard, {
                                                                                            text: apiKey.key,
                                                                                            children: JsxRuntime.jsx("a", {
                                                                                                  children: "Copy",
                                                                                                  className: forms.dropdownBodyLink,
                                                                                                  href: "#"
                                                                                                })
                                                                                          })
                                                                                    }),
                                                                                JsxRuntime.jsx(Control.make, {
                                                                                      className: forms.dropdownBodyRow,
                                                                                      children: JsxRuntime.jsx("a", {
                                                                                            children: "Delete",
                                                                                            className: forms.dropdownBodyLink,
                                                                                            href: "#",
                                                                                            onClick: (function (param) {
                                                                                                dispatch({
                                                                                                      TAG: "OpenDestroyConfirm",
                                                                                                      _0: apiKey.id
                                                                                                    });
                                                                                              })
                                                                                          })
                                                                                    })
                                                                              ]
                                                                            })
                                                                      ]
                                                                    }),
                                                                className: forms.apiOptions
                                                              })
                                                        ],
                                                        className: forms.apiKey,
                                                        id: String(index)
                                                      }, String(index));
                                          })),
                                    className: forms.apiKeys
                                  })
                            ],
                            className: forms.apiManagerContainer
                          }),
                      className: forms.container
                    }),
                state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: forms.deleteConfirmationText
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch("CloseDestroyConfirm");
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var apiKeyId = state.currentApiKey;
                                                  if (apiKeyId !== undefined) {
                                                    return $$Promise.wait(Api_ApiKey.destroy(Caml_option.valFromOption(apiKeyId), {
                                                                    providerId: provider.id
                                                                  }), (function (x) {
                                                                  if (x.TAG !== "Ok") {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "ProviderEditApi",
                                                                                subModulePath: /* [] */0,
                                                                                value: "make",
                                                                                fullPath: "ProviderEditApi.make"
                                                                              }, "DestroyApiKey::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                  dispatch("CloseDestroyConfirm");
                                                                  updateApiKeys(x._0.apiKeys);
                                                                }));
                                                  } else {
                                                    return dispatch("CloseDestroyConfirm");
                                                  }
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: forms.deleteConfirmationButtons
                                    })
                              ],
                              className: forms.deleteConfirmationDialog
                            }),
                        className: forms.deleteConfirmationOverlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null
              ]
            });
}

var make = ProviderEditApi;

export {
  css ,
  forms ,
  ApiKeyForm ,
  make ,
}
/* css Not a pure module */
