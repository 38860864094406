// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateProviderFeatureRequest from "./requests/CreateProviderFeatureRequest.res.js";
import * as UpdateProviderFeatureRequest from "./requests/UpdateProviderFeatureRequest.res.js";
import * as DestroyProviderFeatureRequest from "./requests/DestroyProviderFeatureRequest.res.js";

var create = CreateProviderFeatureRequest.exec;

var destroy = DestroyProviderFeatureRequest.exec;

var update = UpdateProviderFeatureRequest.exec;

export {
  create ,
  destroy ,
  update ,
}
/* CreateProviderFeatureRequest Not a pure module */
