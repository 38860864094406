// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(master) {
  return Json_Encode$JsonCombinators.object([[
                "master",
                master
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(id, master) {
  return Rest.$$fetch("/dashboard/provider_settings/" + ID.toString(id), {
              NAME: "Patch",
              VAL: toJson(master)
            }, {
              NAME: "Json",
              VAL: Provider.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
