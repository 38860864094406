// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateProjectSettingProviderRequest from "./requests/UpdateProjectSettingProviderRequest.res.js";

var update = UpdateProjectSettingProviderRequest.exec;

export {
  update ,
}
/* UpdateProjectSettingProviderRequest Not a pure module */
