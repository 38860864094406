// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ApiKey from "../../../models/ApiKey.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              apiKeys: field.required("apiKeys", Json_Decode$JsonCombinators.array(ApiKey.decoder))
            };
    });

var Res = {
  decoder: decoder
};

export {
  Res ,
}
/* decoder Not a pure module */
