// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "provider_id",
                ID.toJson(req.id)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(providerId) {
  return Rest.$$fetch("/dashboard/provider_features", {
              NAME: "Post",
              VAL: toJson(providerId)
            }, {
              NAME: "Json",
              VAL: Provider.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
