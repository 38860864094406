// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateProviderSettingsMaster from "./requests/UpdateProviderSettingsMaster.res.js";
import * as UpdateProviderSettingsRequest from "./requests/UpdateProviderSettingsRequest.res.js";
import * as UpdateProviderSettingsAgreementRequest from "./requests/UpdateProviderSettingsAgreementRequest.res.js";

var update = UpdateProviderSettingsRequest.exec;

var updateAgreement = UpdateProviderSettingsAgreementRequest.exec;

var updateMaster = UpdateProviderSettingsMaster.exec;

export {
  update ,
  updateAgreement ,
  updateMaster ,
}
/* UpdateProviderSettingsMaster Not a pure module */
